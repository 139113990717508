import { computed, ref } from "vue"
import { defineStore } from "pinia"
import type { SupportingDocument } from "@/helpers/interface/trueapp"
import { useAPI } from "@/helpers/services/api"
import type { SummaryDetail } from "@/helpers/interface/appvet"
import type { HistoryAPIResponse } from "@/helpers/interface/candidates"
import type { Auth0User } from "@/helpers/interface/admin-page"

export const useTaxroll = defineStore("taxroll", () => {

    const api = useAPI()
    const quickSearchQuery = ref("")
    const setQuickSearchQuery = (qs: string) => quickSearchQuery.value = qs
    const getQuickSearchQuery = computed(() => quickSearchQuery.value)
    const documentList = ref<SupportingDocument[]>([])
    const fetchDocumentListLoading = ref(true)
    
    const appSummaryDetail = ref<SummaryDetail | null>(null)
    const fetchAppSummaryDetailLoading = ref(true)
    
    const appHistoryList = ref<HistoryAPIResponse[] | null>(null)
    const fetchAppHistoryListLoading = ref(true)

    const fetchUserInfoLoading = ref(true)
    const cacheUserInfo = ref<Auth0User[]>([])

    const fetchDocumentList = async (tru_id: string, errorHandler: Function) => {
        fetchDocumentListLoading.value = true

        try {
            const { data } = await api.get(`applications/supporting_documents/${tru_id}`)
            documentList.value = data
        }  
        catch (error: unknown) {
            errorHandler(error)
        }
        fetchDocumentListLoading.value = false
    }

    const fetchAppSummaryDetail = async (tru_id: string, errorHandler: Function) => {
        fetchAppSummaryDetailLoading.value = true
        try {
            const { data } = await api.get(`applications/${tru_id}/detail`)
            appSummaryDetail.value = data
        } 
        catch (error: unknown) {
            errorHandler(error)
        }
        fetchAppSummaryDetailLoading.value = false
    }

    const fetchAppHistoryList = async (tru_id: string, errorHandler: Function) => {
        fetchAppHistoryListLoading.value = true
        try {
            const { data } = await api.get(`applications/history?application_id=${tru_id}`)
            appHistoryList.value = data
        } 
        catch (error: unknown) {
            errorHandler(error)
        }
        fetchAppHistoryListLoading.value = false
    }

    const fetchUserInfo = async (userId: string, errorHandler: Function) => {
        fetchUserInfoLoading.value = true

        const userInfo = cacheUserInfo.value.filter(user => user.user_id === userId)?.[0] || null
        if (userInfo) {
            fetchUserInfoLoading.value = false
            return
        }

        try {
            const { data } = await api.get(`users/auth0/${userId}/detail`)
            cacheUserInfo.value.push(data)
        } 
        catch (error: unknown) {
            errorHandler(error)
        }
        fetchUserInfoLoading.value = false
    }

    const getDocumentList = computed(() => documentList.value)
    const getAppSummaryDetail = computed(() => appSummaryDetail.value)
    const getAppHistoryList = computed(() => appHistoryList.value)
    const getDocumentListLoadingState = computed(() => fetchDocumentListLoading.value)
    const getUserInfo = computed(():Auth0User[] => cacheUserInfo.value)

    return {
        cacheUserInfo,
        appSummaryDetail,
        appHistoryList,
        fetchAppHistoryListLoading,
        fetchUserInfoLoading,
        fetchAppSummaryDetailLoading,
        fetchUserInfo,
        getAppSummaryDetail,
        getAppHistoryList,
        setQuickSearchQuery,
        getQuickSearchQuery,
        fetchDocumentList,
        fetchAppSummaryDetail,
        fetchAppHistoryList,
        getDocumentListLoadingState,
        getDocumentList,
        getUserInfo
    }
})
