<template>
    <div style="width: 250px;">
        <div v-if="loading" class="row">
            <div class="col-3">
                <Skeleton width="45px" height="45px" />
            </div>
            <div class="col-9">
                <Skeleton width="120px" height="10px" class="mb-1" />
                <Skeleton width="130px" height="10px" class="mb-1" />
            </div>
        </div>

        <div v-else>
            <div v-if="data" class="row">
                <div class="col-3">
                    <img :src="data.picture" :alt="data.name" class="users-list-picture">
                </div>
                <div class="col-9">
                    <span :class="{ 'tr-icon user-info-icon': data.app_metadata.is_client }"></span>
                    {{ data.name }}
                    <a :href="`mailto: ${data.email}`" class="d-flex mt-1">
                        <i class="fa fa-envelope me-2"></i>
                        {{ truncateText(data.email, 20) }}
                    </a>
                    <div class="mt-1">
                    <span v-if="data.blocked" class='user-blocked'>
                        <i class="fa fa-times-circle me-1"></i> Blocked
                    </span>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { getApiErrorMessage, truncateText } from '@/helpers/common';
import type { Auth0User } from '@/helpers/interface/admin-page';
import { toast } from '@/helpers/toast';
import { useTaxroll } from '@/stores/taxroll'
import Skeleton from 'primevue/skeleton'
import { computed, onMounted, ref } from 'vue'

const storeTaxroll = useTaxroll()
const cachedUserInfos = computed((): Auth0User[] => storeTaxroll.getUserInfo)
const data = ref<Auth0User | null>(null)
const loading = computed(() => storeTaxroll.fetchUserInfoLoading)

const { userId } = defineProps<{
    userId: string
}>()

const loadFailureHandler = (error: any) => {
    const message = getApiErrorMessage(error)
    toast.error(message)
}

onMounted(async () => {
    await storeTaxroll.fetchUserInfo(userId, loadFailureHandler)
    data.value = cachedUserInfos.value.filter(user => user.user_id === userId)?.[0] || null
})
</script>