<template>
  <main class="p-2">
     <button class="searchButton cbFormTextField-sm mt-2" type="button"
      @click="SendToWindow($router.resolve({name: 'onboardingToolbox'}).href)">
        Launch Onboarding Toolbox
    </button>
  </main>
</template>
<script setup lang="ts">
const SendToWindow = (url: string) => {
  window.open(url, '_blank')
}
</script>
