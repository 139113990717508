<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><router-link :to="{name: 'onboardingToolbox'}">Onboarding Toolbox</router-link></li>
      <li class="breadcrumb-item active" aria-current="page">{{ newConfig ? "Create New": `${currentCustomer?.name || ('#'+state.config?.customer_id)} (${state.config?.id})` }}</li>
    </ol>
  </nav>
  <div v-if="!state.customers || state.customersLoading" class="spinner-border" role="status"><span class="visually-hidden">Loading customers list...</span></div>
  <div v-else class="row">
    <div class="row">
      <div class="col was-validated">
        <input type="text"  id="customerInput" class="form-control" placeholder="Select Customer" list="customers" v-model="customerSelectedLabel" :readonly="!newConfig" required>
        <datalist id="customers">
          <option
            v-for="customer in state.customers"
            :key="customer.customer_id"
            :value="`(${customer.customer_id}) ${customer.name} ${customer.fips_code} ${customer.schema_name}`"
          ></option>
        </datalist>
        <div class="invalid-feedback">
          Please select a customer
        </div>
      </div>
      <div class="col was-validated">
        <input type="text"  id="nameInput" class="form-control" placeholder="Config Name" v-model="state.formValues.name" required>
        <div class="invalid-feedback">
          Please name this config
        </div>
      </div>
      <div class="col was-validated" :hidden="newConfig">
        <input type="text"  id="commentInput" class="form-control" placeholder="Comment" v-model="state.formValues.comment" required>
        <div class="invalid-feedback">
          Please provide a comment explaining the update
        </div>
      </div>
      <div class="col">
        <button type="button" class="btn btn-outline-primary btn-sm m-1" @click="saveConfig" :disabled="state.configLoading">{{ newConfig?'Save new config':'Update config' }}</button>
        <button type="button" class="btn btn-outline-primary btn-sm m-1" @click="runConfig" :disabled="newConfig">{{ 'Run' }}</button>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <button type="button" class="btn btn-outline-primary btn-sm m-1" @click="toggleNotification" :disabled="!state.config">Assign Config</button>
      </div>
    </div>
  </div>
  <codemirror
    v-model="state.formValues.body"
    placeholder="Config JSON"
    :style="{ height: '100%' }"
    :autofocus="true"
    :indent-with-tab="true"
    :tab-size="2"
    :extensions="extensions"
    @ready="handleReady"
    @update="handleUpdate"
  />
  <div class="breadcrumbs-panel">
    <span v-for="b in breadcrumbs" @click="jumpTo(b.itemNode || b.prop?.node || b.node)" class="breadcrumb-span" :key="b.breadcrumbIndex">{{ b.text }}</span>
  </div>
  <teleport to="body">
		<div class="modal-overlay" v-if="notificationVisibility.showNotification" @click="toggleNotification"></div>
		<OnboardingConfigPopup @closePopup="toggleNotification" class="notification" :config_id="state.config?.id || ''"
			v-if="notificationVisibility.showNotification" />
	</teleport>
</template>

<script setup lang="ts">
import { Codemirror } from 'vue-codemirror'
import { json, jsonParseLinter } from '@codemirror/lang-json'
import type { CompletionContext, CompletionResult, Completion } from "@codemirror/autocomplete"
import { autocompletion, insertCompletionText, pickedCompletion, startCompletion } from "@codemirror/autocomplete"
import { linter, lintGutter } from '@codemirror/lint'
import type { Diagnostic } from '@codemirror/lint'
import {computed, reactive, onMounted, ref, shallowRef, onBeforeUnmount} from "vue";
import { useRouter } from "vue-router";
import { useAPI } from "@/helpers/services/api";
import { toast } from "@/helpers/toast";
import { useConfigEditorCache } from '@/stores/configEditor'
import type { EditorView } from 'codemirror'
import type { Customer } from '@/helpers/interface/trueload'
import type { Config, UpdateConfig, CreateConfig } from '@/helpers/interface/onboarding'
import { hidePFGetHelpButton } from '@/helpers/common'
import OnboardingConfigPopup from "@/components/Admin/Onboarding/Onboarding-configPopup.vue";


const api = useAPI()


const router = useRouter()
const dataConfigId = computed(() => router.currentRoute.value.params.id as string)
const newConfig = computed(() => dataConfigId.value === 'new')
const cache = useConfigEditorCache()
const state = reactive({
  config: null as Config | null,
  configLoading: false,
  customers: [] as Array<Customer>,
  customersLoading: false,
  formValues: {
    customer_id: '',
    name: '',
    body: '',
    comment: '',
  } as any,
})

const notificationVisibility = ref({ showNotification: false })
const toggleBodyScrolling = (allowScrolling: boolean) => document.body.style.overflow = allowScrolling ? 'auto' : 'hidden';
const toggleNotification = () => {
	notificationVisibility.value.showNotification = !notificationVisibility.value.showNotification
	toggleBodyScrolling(!notificationVisibility.value.showNotification);
}
onBeforeUnmount(() => toggleBodyScrolling(true))

const customerSelectedLabel = computed({
  get() {
    return state.formValues.customer_id ? `(${state.formValues.customer_id}) ${state.customers.find(c => c.customer_id === state.formValues.customer_id)?.name}` : ''
  },
  set(value) {
    const match = value.match(/^\(([^)]+)\)/)
    if (match) {
      state.formValues.customer_id = match[1]
      const customer = getCustomerById(match[1])
      if (customer && !state.formValues.body) {
        state.formValues.body = JSON.stringify({
          customer_id: customer.customer_id,
          fips_code: customer.fips_code,
          schema_name: customer.schema_name,
          county_display_name: customer.name?.replace(/, [A-Z]{2}$/, ''),
          state_abbrev: customer.state?.toUpperCase(),
          d2p: '',
          datasets: [],
          exemptions: []
        }, null, 2)
      }
    }
  }
})

const currentCustomer = computed(() => getCustomerById(state.formValues.customer_id))

const getAllChildren = (node: any) => {
  if (!node) {
    return []
  }
  const children = []
  let c = node.firstChild
  while (c) {
    children.push(c)
    c = c.nextSibling
  }
  return children
}

const JsonNodeNames = [
  "String", "Null", "True", "False", "Number", "Object", "Array"
]

const getArrayChildren = (node: any) => {
  return getAllChildren(node).filter((n: any) => JsonNodeNames.includes(n.name))
}



const formattedObjectOption = (obj: any, indent: string, detail: string, moveCursorTo?: string, autocomplete?: boolean, suffix?: string ) => {
  const shortJSON = JSON.stringify(obj)
  const prettyJSON = JSON.stringify(obj, null, 2).split('\n').join(`\n${indent}`) + (suffix || '')

  return {
    label: shortJSON,
    detail,
    type: "text",
    apply: (view: any, completion: any, from: any, to: any)=> {
      view.dispatch({
        ...insertCompletionText(view.state, prettyJSON, from, to),
        annotations: pickedCompletion.of(completion)
      })
      const cursorOffset = moveCursorTo? prettyJSON.indexOf(moveCursorTo) + moveCursorTo.length: 0
      view.dispatch({
        selection: {
          anchor: from + cursorOffset,
          head: from + cursorOffset,
        },
        scrollIntoView: true,
      })
      if (autocomplete) {
        startCompletion(view)  // start completion again
      }
    }
  }
}

const configLinter = (view: EditorView) : Diagnostic[] => {
  let diagnostics: Diagnostic[] = []
  const tree = (view.state as any).tree
  const jsontext = tree.topNode
  const root = jsontext.firstChild
  const defaultError = (error: string, node: any) => {
    diagnostics.push({
      from: (node || jsontext).from,
      to: (node || jsontext).to,
      severity: "error",
      message: error,
      actions: []
    })
  }
  if (!root || root.name !== 'Object') {
    defaultError("Root node must be an object", root.firstChild)
    return diagnostics
  }
  const rootObj = getNodeObject(root)
  for (const col of ['datasets', 'exemptions']) {
    if (!rootObj[col]) {
      defaultError(`Config must include ${col} array`, root.firstChild)
    } else {
      const colValueNode = rootObj[col].node.lastChild
      if (colValueNode.name != 'Array') {
        defaultError(`${col} must be an array`, colValueNode)
      } else if (getArrayChildren(colValueNode).length == 0) {
        defaultError(`${col} array must not be empty`, colValueNode)
      }
    }
  }
  if (!rootObj['d2p']) {
    defaultError(`D2P link expected (SQL expression)`, root.firstChild)
  }
  if(!rootObj['fips_code'] && (!rootObj['state_abbrev'] || !rootObj['county_display_name'])) {
    defaultError(`Either fips_code or state_abbrev & county_display_name expected`, root.firstChild)
  }
  const datasetsArrNode = rootObj.datasets?.node?.lastChild
  const destinations = []
  for (const dsNode of getArrayChildren(datasetsArrNode)) {
    const dsObj = getNodeObject(dsNode)
    if (!dsObj.key) {
      defaultError(`Dataset must include "key" containing s3:// uri of the dataset source file`, dsNode.firstChild)
    }
    if (!dsObj.destinations) {
      defaultError(`Dataset must include destinations array`, dsNode.firstChild)
    }
    const destinationsArrNode = dsObj.destinations?.node?.lastChild
    for (const dstNode of getArrayChildren(destinationsArrNode)) {
      const dstObj = getNodeObject(dstNode)
      if (!dstObj.destination) {
        defaultError(`Destination must include "destination" containing trueroll table name`, dstNode.firstChild)
      } else {
        const destinationName = getNodeText(dstObj.destination.node.lastChild).replace(/^"|"$/g, '')
        destinations.push(destinationName)
      }
    }
  }
  for (const dst of ['parcels', 'owners', 'exemptions', 'addresses']) {
    if (!destinations.includes(dst)) {
      defaultError(`${dst} table isn't filled by any dataset`, datasetsArrNode?.firstChild)
    }
  }
  // todo: more linter errors & warnings
  return diagnostics
}


function arrayValueCompletions(context: CompletionContext): CompletionResult | null {
  const explicit = context.explicit  // flag indicates whether the completion was started explicitly, via the command, or implicitly, by typing
  if (!breadcrumbs.value) {
    return null
  }

  const revBreadcrumbs = [...breadcrumbs.value].reverse()
  const currentBc = revBreadcrumbs[0]
  if (!currentBc || currentBc.type != "Array") {
    return null
  }
  const parentBc = revBreadcrumbs.length > 1? revBreadcrumbs[1]: null
  // const grandParentBc = revBreadcrumbs.length > 2? revBreadcrumbs[2]: null

  const prefixRegex = /(?<indent>^[ \t]*)|(?<arrayValueEnd>[[,][ \t]*)?/
  const prefix = context.matchBefore(prefixRegex)
  if (!prefix || prefix.from == prefix.to) {
    return null
  }
  const match = prefix.text.match(prefixRegex)
  if (!explicit && !match?.groups?.indent) {
    return null
  }
  const rightmostItems = getAllChildren(currentBc.node).filter((n: any) => n.to > prefix.to && !']⚠'.includes(n.name))
  const hasRightmostItems = rightmostItems.length > 0
  
  const isDatasetArray = currentBc.parentKey === "datasets" && currentBc.type === "Array"
  const isDestinationArray = currentBc.parentKey === "destinations" && currentBc.type === "Array"
  const isTransformArray = currentBc.parentKey === "transform" && currentBc.type === "Array"
  const isFunctionArgsArray = currentBc.parentKey === "args" && currentBc.type === "Array" && !!parentBc?.obj.function
  const isAggregateArgsArray = currentBc.parentKey === "args" && currentBc.type === "Array" && parentBc?.parentKey === 'aggregate'
  const isExemptionArray = currentBc.parentKey === "exemptions" && currentBc.type === "Array"
  const options = []

  if (isDatasetArray) {
    options.push(
      formattedObjectOption({id: "", key: "", destinations: []}, match?.groups?.indent || "", "Dataset boilerplate", '"key": "', false, (hasRightmostItems ? ',' : '')))
  } else if (isDestinationArray) {
    options.push(formattedObjectOption({ destination: "", transform: []}, match?.groups?.indent || "", "Destination boilerplate", '"destination": "', true, (hasRightmostItems ? ',' : '')))
  } else if (isTransformArray) {
    options.push(formattedObjectOption({ function: "", args: []}, match?.groups?.indent || "", "Transform boilerplate", '"function": "', true, (hasRightmostItems ? ',' : '')))
  } else if (isFunctionArgsArray) {
    options.push(formattedObjectOption({ function: "", args: []}, match?.groups?.indent || "", "Function boilerplate", '"function": "', true, (hasRightmostItems ? ',' : '')))
    options.push(...columnNameOptions((hasRightmostItems ? ',' : '')))
  } else if (isExemptionArray) {
    options.push(formattedObjectOption({ code: "", description: "", is_pre: false, category: "", low_income: false}, match?.groups?.indent || "", "Exemption boilerplate", '"code": "', false, (hasRightmostItems ? ',' : '')))
  } else if (isAggregateArgsArray) {
    options.push(formattedObjectOption({}, match?.groups?.indent || "", "Aggregate rule (pd.aggregate(rule))", '{', true, (hasRightmostItems ? ',' : '')))
  }
  return {
    from: prefix.to,
    to: prefix.to,
    options,
    filter: false
  }
}

function objectParamNameCompletion(context: CompletionContext): CompletionResult | null {
  const explicit = context.explicit  // flag indicates whether the completion was started explicitly, via the command, or implicitly, by typing
  if (!breadcrumbs.value) {
    return null
  }
    
  const revBreadcrumbs = [...breadcrumbs.value].reverse()
  const currentBc = revBreadcrumbs[0]
  if (!currentBc || currentBc.type !== "Object") {
    return null
  }
  const prefixRegex = /((?<indent>^[ \t]*)|(?<objectParamEnd>[{,][ \t]*))(?<paramNameBegin>"[^"]*)?/
  const prefix = context.matchBefore(prefixRegex)
  if (!prefix || prefix.from == prefix.to) {
    return null
  }
  const match = prefix.text.match(prefixRegex)
  if (!explicit && !match?.groups?.indent) {
    return null
  }
  const paramNameBeforeCursor = match?.groups?.paramNameBegin
  const contentAfterCursor = context.state.doc.sliceString(prefix.to, prefix.to + 100)
  const paramNameAfterCursor = !paramNameBeforeCursor? contentAfterCursor.match(/^(?<paramName>"[^"]*")(?<colon>[ ]*:)?/): null
  const replFrom = prefix.to - (paramNameBeforeCursor ? paramNameBeforeCursor.length : 0)
  let replTo = prefix.to
  let hasColon = false
  let hasRightmostParams = currentBc.node.getChildren("Property").filter((n: any) => n.to > prefix.to).length > 0
  if (paramNameBeforeCursor) {
    const suffixMatch = contentAfterCursor.match(/(?<paramNameEnd>^[^"\n]*?")(?<colon>[ ]*:)?/)
    if (suffixMatch) {
      replTo += suffixMatch.groups?.paramNameEnd.length || 0
      hasColon = !!suffixMatch.groups?.colon
    }
  } else if (paramNameAfterCursor) {
    replTo += paramNameAfterCursor.groups?.paramName.length || 0
    hasColon = !!paramNameAfterCursor.groups?.colon
  }
  const repl = context.state.doc.sliceString(replFrom, replTo)
  // we're inside {} and either there's no prop name yet or we're inside it
  
  const currentObj = currentBc.obj
  const parentBc = revBreadcrumbs.length > 1? revBreadcrumbs[1]: null
  const grandParentBc = revBreadcrumbs.length > 2? revBreadcrumbs[2]: null
  // figure out context of the current object
  const isRoot = parentBc === null
  const isDataset = parentBc && parentBc.type === "Array" && parentBc.parentKey === "datasets"
  const isDestination = parentBc && parentBc.type === "Array" && parentBc.parentKey === "destinations"
  const isTransformFunction = parentBc && parentBc.type === "Array" && parentBc.parentKey === "transform"
  const isFunction = currentObj.function
  const isFunctionKwargs = currentBc.parentKey === "kwargs" && parentBc.type === "Object" && parentBc.prop?.name === "kwargs" && parentBc.obj.function
  const isFunctionArg = parentBc?.type === "Array" && parentBc.parentKey === "args" && !!grandParentBc?.obj.function
  const isMappingRule = isFunctionArg && currentBc.parentIx === 0 && grandParentBc?.obj?.function?.value === '"mapping"'
  const isPandasKwargs = currentBc.parentKey === "pandas_kwargs" && parentBc.type === "Object"
  const isJoin = currentBc.parentKey === "join" && parentBc.type === "Object"
  const isExemption = parentBc && parentBc.type === "Array" && parentBc.parentKey === "exemptions"
  const isAggregate = (
    currentBc.parentKey === 'aggregate' && 
    parentBc?.parentKey === 'kwargs' &&
    grandParentBc?.obj?.function?.value === '"groupby"'
  )
  const isAggregateRule = (
    currentBc.parentIx === 0 && grandParentBc?.parentKey === 'aggregate'
  )

  const defaultApply = (view: any, completion: any, from: any, to: any)=> {
    let completionContent = completion.label
    let cursorMove = null
    if (!hasColon) {
      completionContent += ': '

      let defaultValue = completion.detail
      if (defaultValue) {
        if (defaultValue.includes('|')) {
          defaultValue = defaultValue.split('|')[0].trim()
        }
        const defautlValueMatch = defaultValue?.match(/^(?<prefix>["[{])|(?<literal>true|false|null|[0-9])/)
        
        if (defautlValueMatch.groups?.literal) {
          cursorMove = {
            anchor: from + completionContent.length,
            head: from + completionContent.length + defautlValueMatch.groups?.literal.length,
            from: from + completionContent.length,
            to: from + completionContent.length + defautlValueMatch.groups?.literal.length
          }
          completionContent += defaultValue
        } else if (defautlValueMatch.groups?.prefix) {
          cursorMove = {
            anchor: from + completionContent.length + 1,
            head: from + completionContent.length + 1,
          }
          completionContent += ({
            '"': '""', 
            '[': '[]', 
            '{': '{}'
          } as any)[defautlValueMatch.groups.prefix]
        }
        if (hasRightmostParams) {
          completionContent += ','  // add comma if there are more params after this one
        }
      }
    }
    view.dispatch({
      ...insertCompletionText(view.state, completionContent, from, to),
      annotations: pickedCompletion.of(completion)
    })
    
    if (cursorMove) {
      view.dispatch({
        selection: cursorMove,
        scrollIntoView: true,
      })
      startCompletion(view)  // start completion again
    }
    
  }

  const options: Completion[] = []
  if (isRoot) {
    options.push(...[
      { label: '"customer_id"', type: "text", detail: `"${currentCustomer.value?.customer_id || '46'}"`, info: "Internal customer id (customers.customers.customer_id in DB)" },
      { label: '"state_abbrev"', type: "text", detail: `"${currentCustomer.value?.state || 'IL'}"`, info: "State abbreviation" },
      { label: '"county_display_name"', type: "text", detail: `"${currentCustomer.value?.name?.replace(/, [A-Z]{2}$/, '') || "Madison County"}"`, info: "County name" },
      { label: '"fips_code"', type: "text", detail: `"${currentCustomer.value?.fips_code || '12079'}"`, info: "County FIPS code" },
      { label: '"schema_name"', type: "text", detail: `"${currentCustomer.value?.schema_name || 'fl_madison_county'}"`, info: "Database schema name, optional if state & country name provided" },
      { label: '"d2p"', type: "text", detail: '"concat(\'https://qpublic.schneidercorp.com/Application.aspx?AppID=911&PageTypeID=4&KeyValue=\', parcel_num)"', info: "SQL expression to define a D2P link for a parcel" },
      { label: '"datasets"', type: "text", detail: "[{...}]", info: "Datasets array" },
      { label: '"exemptions"', type: "text", detail: "[{...}]", info: "Exemptions array" },
      { label: '"refresh"', type: "text", detail: "false | true", info: "Refresh flag" },
      { label: '"integration"', type: "text", detail: "{...}", info: "(optional) Integration config" },
      { label: '"poach_city"', type: "text", detail: "true | false", info: "(optional), Whether to poach city data (defaults to false)" },
      { label: '"cama"', type: "text", detail: '"gsa"', info: "(optional) CAMA integration name" },
      { label: '"source_date"', type: "text", detail: '"2023-03-17"', info: "(optional) Overrides source date. Defaults to the the dataset date, falls back to today" },
    ])
  } else if (isDataset) {
    options.push(...[
      { label: '"id"', type: "text", detail: '"some dataset id"', info: "Dataset id (optional, defaults to filename)" },
      { label: '"key"', type: "text", detail: '"s3://..."', info: "S3 key of the dataset" },
      { label: '"pandas_kwargs"', type: "text", detail: "{ ... }", info: "Keyword arguments for pandas.read_csv" },
      { label: '"destinations"', type: "text", detail: "[{destination: ..., transform: [...]}]", info: "List of destinations for this dataset" },
    ])
  } else if (isPandasKwargs) {
    options.push(...[
      { label: '"names"', type: "text", detail: '["pin", "mkt_val"...]', info: "List of column names" },
      { label: '"sep"', type: "text", detail: '","', info: "Separator between columns" },
      { label: '"quotechar"', type: "text", detail: '"~"', info: "Quote character" },
      { label: '"encoding"', type: "text", detail: '"cp1252"', info: "Sometimes it's not UTF-8" },
      { label: '"skip_blank_lines"', type: "text", detail: 'true', info: "Skip blank lines" },
      { label: '"fix_unescaped_quotes"', type: "text", detail: 'true', info: "Attempt to fix unescaped quotes in CSV (risky, lossy)" },
      { label: '"error_bad_lines"', type: "text", detail: 'false', info: "Whether to skip lines with errors" },
      { label: '"header"', type: "text", detail: '0', info: "Whether the file has a header row" },
      { label: '"index_col"', type: "text", detail: 'true', info: "Whether to use the first column as index" },
      { label: '"engine"', type: "text", detail: '"openpyxl"', info: "Override default Excel engine to use" },
      { label: '"fix_invalid_colnames"', type: "text", detail: 'true', info: "Attempt to fix invalid column names" },
    ])
  } else if (isDestination) {
    options.push(...[
      { label: '"destination"', type: "text", detail: '"parcels"', info: "Destination table name" },
      { label: '"transform"', type: "text", detail: "[{function: ..., args: [...]}]", info: "List of transform functions to apply to this dataset" },
      { label: '"join"', type: "text", detail: '{how: "left", on: "pin"}', info: "(optional) join options (when multiple datasets go to the same destination table )" },
    ])
  } else if (isJoin) {
    options.push(...[
      { label: '"how"', type: "text", detail: '"left" | "inner"', info: "Join type, defaults to inner" },
      { label: '"on"', type: "text", detail: '"pin"', info: "Common column to join on" },
    ])
  } else if (isTransformFunction || isFunction || isFunctionArg && !isMappingRule) {
    options.push(...[
      { label: '"function"', type: "text", detail: '"mapping"', info: "Function name" },
      { label: '"args"', type: "text", detail: "[...]", info: "Function arguments" },
      { label: '"kwargs"', type: "text", detail: "{...}", info: "Function keyword arguments" },
    ])
  } else if (isAggregate) {
    options.push(...[
      { label: '"args"', type: "text", detail: "[...]", info: "Aggregate arguments (pd.aggregate(...))" },
      { label: '"kwargs"', type: "text", detail: "{...}", info: "Aggregate kwargs (pd.aggregate(...))" },
    ])
  } else if (isAggregateRule) {
    options.push(...[
      ...columnNameOptions().map(o => ({...o, detail: '"sum"'}))
    ])
  } else if (isFunctionKwargs) {
    const functionName = isFunctionKwargs? parentBc.obj.function.value : grandParentBc?.obj.function?.value
    switch (functionName) {
      case '"concat"': {
        options.push({ label: '"sep"', type: "text", detail: '"; "', info: "Separator between args" })
        break
      }
      case '"format"': {
        options.push({ label: '"format"', type: "text", detail: '"{first_name} {last_name}"', info: "Format string" })
        break
      }
      case '"regex_sub"': {
        options.push({ label: '"pattern"', type: "text", detail: '"\\\\d{1,2}/\\\\d{1,2}|\\\\(|\\\\)|\\\\d{1,2}\\\\.\\\\d{1,2}%?"', info: "Regex pattern. Escape slashes (\\ -> \\\\) and quotes (\"->\\\")" })
        options.push({ label: '"repl"', type: "text", detail: '"\\\\1"', info: "Replacement string" })
        break
      }
      case '"regex_search"': {
        options.push({ label: '"pattern"', type: "text", detail: '"\\\\d{1,2}/\\\\d{1,2}|\\\\(|\\\\)|\\\\d{1,2}\\\\.\\\\d{1,2}%?"', info: "Regex pattern. Escape slashes (\\ -> \\\\) and quotes (\"->\\\")" })
        options.push({ label: '"group"', type: "text", detail: '1', info: "Group number or name, defaults to 1" })
        break
      }
      case '"eval"': {
        options.push({ label: '"expr"', type: "text", detail: '\\"to be\\" or \\"not to be\\"', info: "Python expression to evaluate on each row" })
        break
      }
      case '"df_eval"': {
        options.push({ label: '"expr"', type: "text", detail: "df['Exempt Type'].str.split(',')", info: "Python expression, must return pd.Series" })
        break
      }
      case '"str"': {
        options.push({ label: '"method"', type: "text", detail: '"strip" | "lower" | "upper" | etc', info: "(optional) String method to call" })
        break
      }
      case '"parse_date"': {
        options.push({ label: '"format"', type: "text", detail: '"%m/%d/%Y"', info: "(optional) Date format string" })
        break
      }
      case '"sort"': {
        options.push({ label: '"by"', type: "text", detail: '["pin", "date"]', info: "List of columns to sort by" })
        options.push({ label: '"ascending"', type: "text", detail: 'false', info: "(optional) Whether to sort ascending, defaults to true" })
        break
      }
      case '"groupby"': {
        options.push({ label: '"by"', type: "text", detail: '["pin"]', info: "List of columns to group by" })
        options.push({ label: '"aggregate"', type: "text", detail: '{"args": [], "kwargs": []}', info: "params for df.aggregate(...) to apply after groupby"})
        break
      }
      // case '"aggregate"': {
      //   options.push({ label: '"args"', type: "text", detail: '["pin"]', info: "List of columns to aggregate" })
      //   options.push({ label: '"kwargs"', type: "text", detail: '{"sum": "sum", "mean": "mean"}', info: "params for df.agg(...) to apply after groupby"})
      //   break
      // }
      case '"replace"': {
        options.push({ label: '"mapping"', type: "text", detail: '{"Y": "Yes", "N": "No"}', info: "Mapping to apply to the column" })
        options.push({ label: '"columns"', type: "text", detail: '["married_owners"]', info: "(optional) Columns to replace values in, all dataframe columns by default" })
        break
      }
      case '"drop_duplicates"': {
        options.push({ label: '"subset"', type: "text", detail: '["pin", "p_class"]', info: "List of columns to consider when dropping duplicates" })
        options.push({ label: '"keep"', type: "text", detail: '"first"', info: "(optional) Whether to keep first or last duplicate, defaults to first" })
        break
      }
      case '"dropna"': {
        options.push({ label: '"subset"', type: "text", detail: '["pin", "p_class"]', info: "List of columns to consider when dropping nulls" })
        options.push({ label: '"how"', type: "text", detail: '"any"', info: "(optional) Whether to drop rows with any or all nulls, defaults to any" })
        break
      }
      case '"filter"': {
        options.push({ label: '"expr"', type: "text", detail: '"tax_value > 0"', info: "Filter expression" })
        break
      }
      case '"explode"': {
        options.push({ label: '"column"', type: "text", detail: '"code"', info: "Column holding arrays to explode" })
        break
      }
      case '"merge"': {
        options.push({ label: '"right"', type: "text", detail: '"owners"', info: "Name of the destination table to merge with" })
        options.push({ label: '"on"', type: "text", detail: '"pin"', info: "Common column to join on" })
        options.push({ label: '"left_on"', type: "text", detail: '"pin"', info: "Left dataframe column to join on" })
        options.push({ label: '"right_on"', type: "text", detail: '"pin2"', info: "Right dataframe column to join on" })
        options.push({ label: '"how"', type: "text", detail: '"left" | "inner"', info: "(optional) Join type, defaults to inner" })
        break
      }
    }
  } else if (isMappingRule) {
    // let parentDatasetObj = null
    let parentDestination = null
    for (const bc of revBreadcrumbs) {
      if (bc.type == 'Object' && bc.obj.destination) {
        parentDestination = bc.obj.destination
        break
      }
    }
    switch (parentDestination.value) {
      case '"parcels"': {
        options.push(...[
          { label: '"pin"', type: "text", info: "Unique parcel ID" },
          { label: '"pin2"', type: "text", info: "Secondary parcel ID" },
          { label: '"pin3"', type: "text", info: "Secondary parcel ID" },
          { label: '"tax_value"', type: "text", info: "Tax value" },
          { label: '"assessed_value"', type: "text", info: "Assessed value" },
          { label: '"market_value"', type: "text", info: "Market value" },
          { label: '"sale_date"', type: "text", info: "Most recent deed date, only relevant if no transactions table provided" },
          { label: '"p_class"', type: "text", info: "Parcel class code: description" },
          { label: '"legal_description"', type: "text", info: "Semicolon separated list of legal descriptions" },
          { label: '"notes"', type: "text", info: "Semicolon separated list of notes" },
          { label: '"special_owner_type"', type: "text", info: "Special owner type" },
          { label: '"married_owners"', type: "text", info: "Married owners" },
          { label: '"group_name"', type: "text", info: "Group name" },
          { label: '"monitored"', type: "text", info: "True by default, used to exclude leasehold parcels" },
          { label: '"lat"', type: "text", info: "Latitude" },
          { label: '"long"', type: "text", info: "Longitude" },
        ])
        break
      }
      case '"owners"': {
        options.push(...[
          { label: '"pin"', type: "text", info: "FK to parcels table" },
          { label: '"first_name"', type: "text", info: "First name" },
          { label: '"middle_name"', type: "text", info: "Middle name" },
          { label: '"last_name"', type: "text", info: "Last name" },
          { label: '"suffix"', type: "text", info: "Suffix" },
          { label: '"full_name"', type: "text", info: "Full name" },
          { label: '"dob"', type: "text", info: "Date of birth" },
          { label: '"driver_license"', type: "text", info: "Driver's license" },
          { label: '"ssn"', type: "text", info: "Social security number" },
          { label: '"party_id"', type: "text", info: "Party ID" },
        ])
        break
      }
      case '"addresses"': {
        options.push(...[
          { label: '"pin"', type: "text", info: "FK to parcels table" },
          { label: '"address_type"', type: "text", info: "One of 'situs' | 'mail'" },
          { label: '"address_full"', type: "text", info: "Full address" },
          { label: '"house_nbr"', type: "text", info: "House number" },
          { label: '"pre_direction"', type: "text", info: "Pre direction" },
          { label: '"post_direction"', type: "text", info: "Post direction" },
          { label: '"street_name"', type: "text", info: "Street name" },
          { label: '"street_suffix"', type: "text", info: "Street suffix" },
          { label: '"unit_prefix"', type: "text", info: "Unit prefix" },
          { label: '"unit_nbrorletter"', type: "text", info: "Unit number or letter" },
          { label: '"address_line_1"', type: "text", info: "Address line 1" },
          { label: '"address_line_2"', type: "text", info: "Address line 2" },
          { label: '"city"', type: "text", info: "City" },
          { label: '"state"', type: "text", info: "State" },
          { label: '"zip"', type: "text", info: "Zip code" },
          { label: '"lat"', type: "text", info: "Latitude" },
          { label: '"long"', type: "text", info: "Longitude" },
        ])
        break
      }
      case '"transactions"': {
        options.push(...[
          { label: '"pin"', type: "text", info: "FK to parcels table" },
          { label: '"date"', type: "text", info: "Date" },
          { label: '"type"', type: "text", info: "Type" },
          { label: '"qualified_sale"', type: "text", info: "Qualified sale" },
          { label: '"grantor"', type: "text", info: "Previous owner(s)" },
          { label: '"grantee"', type: "text", info: "New owner(s)" },
          { label: '"price"', type: "text", info: "Sale price" },
        ])
        break
      }
      case '"exemptions"': {
        options.push(...[
          { label: '"pin"', type: "text", info: "FK to parcels table" },
          { label: '"code"', type: "text", info: "Code" },
          { label: '"description"', type: "text", info: "Code description" },
          { label: '"start_date"', type: "text", info: "Start date" },
          { label: '"end_date"', type: "text", info: "End date" },
          { label: '"party_id"', type: "text", info: "Owners.party_id or owners.full_name" },
        ])
        break
      }
    }
  } else if (isExemption) {
    options.push(...[
      { label: '"code"', type: "text", detail: '"HS"', info: "Exemption code" },
      { label: '"description"', type: "text", detail: '"Homestead"', info: "Exemption description" },
      { label: '"is_pre"', type: "text", detail: 'true', info: "Whether the exemption is pernanent residentship" },
      { label: '"homestead"', type: "text", detail: 'true', info: "Whether the exemption is homestead" },
      { label: '"category"', type: "text", detail: '"homestead"', info: "Exemption category" },
      { label: '"low_income"', type: "text", detail: 'false', info: "Boolean, whether the exemption is low income" },
    ])
  }
  options.forEach(o => {
    o.apply = o.apply || defaultApply
  })

  return {
    from: replFrom,
    to: replTo,
    options,
    filter: !['""', '"'].includes(repl)
  }
}

const columnNameOptions = (suffix?: string): Completion[] => {
  if (!breadcrumbs.value) {
    return []
  }
  const revBreadcrumbs = [...breadcrumbs.value].reverse()
  /*
  datasets: [{
    destinations: [{
      transform: [{
        ...
      }]
    }]
  }]
  */
  let datasetObjBc = null
  let destinationBc = null
  let transformArrayBc = null
  let transformObjectBc = null
  for (const [i, bc] of revBreadcrumbs.entries()) {
    if (
      bc.type == 'Object' && bc.parentIx !== undefined // bc is [obj]
      && revBreadcrumbs[i + 1]?.type == 'Array' 
      && revBreadcrumbs[i + 1]?.parentKey == 'transform' // transform: [...]
     ) {
      transformObjectBc = bc
      transformArrayBc = revBreadcrumbs[i + 1]
      destinationBc = revBreadcrumbs[i + 2]
      // revBreadcrumbs[i + 3]  // destinations array
      datasetObjBc = revBreadcrumbs[i + 4]
      break
    }
  }
  if (!datasetObjBc || !destinationBc || !transformArrayBc || !transformObjectBc) {
    return []
  }
  
  let options = []
  let columnsDefinedByPrevTransform = false
  if (transformObjectBc.parentIx > 0) { // use previous transform to get columns
    for (let i=transformObjectBc.parentIx - 1 ; i >= 0; i--) {
      const prevTransformObj = getNodeObject(transformArrayBc.arr? transformArrayBc.arr[i].node: null)
      if (prevTransformObj?.function?.value === '"mapping"') {
        const mappingRuleNode = (
          !!prevTransformObj.args && prevTransformObj.args.node.lastChild?.firstChild?.nextSibling  // node is property. lastchild is Value, then firstChild is [, next sibling is actual first item node
          || 
          !!prevTransformObj.kwargs && getNodeObjectParamValueNode(prevTransformObj.kwargs.node, 'mapping')
        )
        if (mappingRuleNode && mappingRuleNode.name === "Object") {
          // const mappingRule = getNodeObject(mappingRuleNode)
          options.push(
            ...mappingRuleNode.getChildren("Property").map(
              (p: any) => getNodeText(p.firstChild)
            ).map(
              (c: string) => ({label: c, type: "text", detail: '(from previous mapping transform)', info: "current dataframe column name"}) as Completion
            )
          )
        }
        columnsDefinedByPrevTransform = true
        break
      } else if (prevTransformObj?.function?.value === '"groupby"') {
        const aggregateParams = getNodeObject(getNodeObjectParamValueNode(prevTransformObj.kwargs?.node.lastChild, 'aggregate')?.lastChild)
        const aggregateArgs = getArrayChildren(aggregateParams.args.node.lastChild)
        const aggregateRule = aggregateArgs? aggregateArgs[0]: null
        if (aggregateRule) {
          options.push(
            ...aggregateRule.getChildren("Property").map(
              (p: any) => getNodeText(p.firstChild)
            ).map(
              (c: string) => ({label: c, type: "text", detail: '(from previous groupby transform)', info: "current dataframe column name"}) as Completion
            )
          )
        }
        columnsDefinedByPrevTransform = true
        break
      }
    }
  }
  if (!columnsDefinedByPrevTransform) {
    // maybe dataset has a pandas_kwargs with names?
    let columnsDefinedByDatasetNames = false
    if (datasetObjBc.obj?.pandas_kwargs) {
      const pandasKwargsObj = getNodeObject(datasetObjBc.obj.pandas_kwargs.node.lastChild)
      if (pandasKwargsObj?.names) {
        columnsDefinedByDatasetNames = true
        const names: string[] = getArrayChildren(pandasKwargsObj.names.node.lastChild).map(getNodeText)
        options.push(
          ...names.map(
            (c: string) => ({label: c, type: "text", detail: '(from pandas_kwargs.names)', info: "current dataframe column name"})
          )
        )
      }
    }

    if (!columnsDefinedByDatasetNames) {
      const key = datasetObjBc.obj?.key?.value?.replace(/^"|"$/g, '')
      const columns = [] as string[]
      if (key) {
        const info = cache.getTableDataInfo(key)
        for (const col of info?.columns || []) {
          if (col.label) {
            columns.push(`"${col.label}"`)
          }
        }
      }
      options.push(
        ...columns.map(
          (c: string) => ({label: c, type: "text", detail: '(from dataset columns)', info: "current dataframe column name"})
        )
      )
    }
  }
  if (suffix) {
    options = options.map(o => ({...o, apply: `${o.label}${suffix}`}))
  }
  return options
}

function objectParamValueCompletions(context: CompletionContext) {
  const explicit = context.explicit  // flag indicates whether the completion was started explicitly, via the command, or implicitly, by typing
  if (!breadcrumbs.value) return null
  const revBreadcrumbs = [...breadcrumbs.value].reverse()
  
  const prefixRegex = /(?<indent>^[ \t]*)?"(?<key>[0-9a-zA-Z_]*)"[ ]*:(?<spaceAfterColon>[ ]*)(?<valueStringLiteralBegin>"[^"]*)?$/
  const prefix = context.matchBefore(prefixRegex)
  // const suffix = context.match(/[^"]*$/)
  if (!prefix || prefix.from == prefix.to && !explicit)
    return null
  const match = prefix.text.match(prefixRegex)
  if (!match) return null
  const key = match.groups!['key']
  const stringBeforeCursor = match.groups!['valueStringLiteralBegin']
  const contentAfterCursor = context.state.doc.sliceString(prefix.to, prefix.to + 100)
  const stringAfterCursor = !stringBeforeCursor? contentAfterCursor.match(/^("[^"]*")/): null
  const replFrom = prefix.to - (stringBeforeCursor ? stringBeforeCursor.length : 0)
  let replTo = prefix.to
  if (stringBeforeCursor) {
    const suffixMatch = contentAfterCursor.match(/(?<valueStringLiteralEnd>^[^"\n]*?)"/)
    if (suffixMatch) {
      replTo += suffixMatch[0].length
    }
  } else if (stringAfterCursor) {
    replTo += stringAfterCursor[1].length
  }
  const repl = context.state.doc.sliceString(replFrom, replTo)
  
  const isFunctionName = key === "function"

  let currentBc = null
  let parentBc = null
  let grandParentBc = null
  for (const bc of revBreadcrumbs) {
    if (currentBc === null && bc.type == 'Object') {
      currentBc = bc
    } else if (currentBc && parentBc === null) {    
      parentBc = bc
    } else if (parentBc && grandParentBc === null) {
      grandParentBc = bc
      break
    }
  }

  const currentObj = currentBc.obj
  const isRoot = parentBc === null
  const isDataset = parentBc && parentBc.type === "Array" && parentBc.parentKey === "datasets"
  const isDestination = parentBc && parentBc.type === "Array" && parentBc.parentKey === "destinations"
  const isTransformFunction = parentBc && parentBc.type === "Array" && parentBc.parentKey === "transform"
  // const isFunction = currentObj?.function
  // const isFunctionKwargs = currentBc.parentKey === "kwargs" && parentBc.type === "Object" && parentBc.prop?.name === "kwargs" && parentBc.obj.function
  const isFunctionArg = parentBc && parentBc.type === "Array" && parentBc.parentKey === "args" && grandParentBc?.obj.function
  const isMappingRule = isFunctionArg && currentBc.parentIx === 0 && grandParentBc?.obj?.function?.value === '"mapping"'
  const isPandasKwargs = currentBc.parentKey === "pandas_kwargs" && parentBc.type === "Object"
  const isJoin = currentBc.parentKey === "join" && parentBc.type === "Object"
  const isExemption = parentBc && parentBc.type === "Array" && parentBc.parentKey === "exemptions"
  const isAggregateRule = (
    currentBc.parentIx === 0 && 
    parentBc?.parentKey === 'args' &&
    grandParentBc?.parentKey === 'aggregate'
  )

  let isMappingArgsContext = false  // we are somewhere inside mapping rule (which can contain folded function calls)
  
  for (const bc of revBreadcrumbs) {
    if (bc.type === "Object" && (bc.prop?.name == 'args' || bc.prop?.name == 'kwargs') && bc.obj.function && bc.obj.function.value === '"mapping"') {
      isMappingArgsContext = true
      break
    }
  }
  const hasRightmostParams = getAllChildren(currentBc.node).filter((n: any) => ['⚠', "Property" ].includes(n.name) && n.from > prefix.to).length > 0
    
  const options = []
  if (isMappingRule) {
    options.push(formattedObjectOption({ function: "", args: []}, match?.groups?.indent || "", "Function boilerplate (mapping)", '"function": "', true, (hasRightmostParams ? ',' : '')))
    options.push(...columnNameOptions(hasRightmostParams? ',': ''))
  } else if (isFunctionName && isMappingArgsContext) {
    options.push(...[
      { label: '"concat"', type: "text", detail: "args: [cols], kwargs: {sep: ...}",  info: "Concatenates non-null arguments. Use sep to set separator, defaults to '; '"},
      { label: '"format"', type: "text", detail: "args: [format]",  info: "Renders python format string with columns namespace (calls fmt.format(**columns))" },
      { label: '"equals"', type: "text", detail: "args: [arg1, arg2]",  info: "Compares args (cols or vals) and returns boolean" },
      { label: '"regex_sub"', type: "text", detail: "args: [col, pattern, repl]",  info: "calls re.sub(pattern, repl, value)" },
      { label: '"regex_search"', type: "text", detail: "args: [col, pattern, group]",  info: "Matches regex, returns specified group (or whole match if group isn't provided)" },
      { label: '"eval"', type: "text", detail: "args: [source]",  info: "evaluates python code for each row, column names are converted into proper python variable names\n(non-alphanumeric chars replaced with _, leading and ending ones are stripped).\nAdditionally eval namespace has `pd` and `np` variables available." },
      { label: '"df_eval"', type: "text", detail: "args: [source]",  info: "similar to eval, but evaluates python code once for whole dataframe (df variable),\nexpected to return a pd.Series. Additional context: pd (pandas lib)" },
      { label: '"str"', type: "text", detail: "args: [col, method]",  info: "converts to string and calls method if provided (like upper(), lower(), etc)" },
      { label: '"sum"', type: "text", detail: "args: [cols]",  info: "returns sum of all args converted to numeric values.\nColumn names can be prepended with a minus sign to negate the value." },
      { label: '"parse_date"', type: "text", detail: "args: [col]",  info: "parses source as a date using the format string or dateutil if no format provided. Returns ISO date string." },
      { label: '"max"', type: "text", detail: "args: [cols]",  info: "returns max of all args converted to numeric values." },
      { label: '"coalesce"', type: "text", detail: "args: [cols]", info: "returns first non-null value" },
      { label: '"float"', type: "text", detail: "args: [col]", info: "Converts column values to numeric type (calls pd.to_numeric)"},
      { label: '"tokenize_last_line"', type: "text", detail: "args: [col, token], kwargs: {default: ...}", info: "Retrieves address token (city / state / zip) from the last line"},
    ].map(
      (o) => ({...o, apply: (match.groups!['spaceAfterColon']?'':' ') + o.label})
    ))
  } else if (isFunctionName && isTransformFunction) {
    options.push(...[
      { label: '"mapping"', type: "text", detail: "args: [{dest: src}]", info: "Dataframe transform function: renders mapping rule, which specifies source for each destination columns. \n source can be either column name, or fixed value, or a function call using {function: ..., args: {}} similar syntax" },
      { label: '"sort"', type: "text", detail: "args: [by]", info: "Sorts dataframe by specified columns" },
      { label: '"groupby"', type: "text", detail: "kwargs: {by: [cols], aggregate: {args: [...]}}", info: "Groups dataframe by specified columns and aggregates values using specified functions" },
      { label: '"replace"', type: "text", detail: "kwargs: {columns: [cols], mapping: {old: new}}", info: "Replaces values according to the mapping in the columns specified (or in all columns if columns is not provided)"},
      { label: '"drop_duplicates"', type: "text", detail: "args: [subset]", info: "Drops duplicate rows (subset is optional list of columns to consider)" },
      { label: '"dropna"', type: "text", detail: "args: [subset]", info: "Drops rows with null values (subset is optional list of columns to consider)" },
      { label: '"filter"', type: "text", detail: "args: [query]", info: "Filters dataframe using query string (see pandas.DataFrame.query)" },
      { label: '"explode"', type: "text", detail: "args: [by]", info: "Explodes dataframe by specified column (see pandas.DataFrame.explode)" },
      { label: '"merge"', type: "text", detail: "kwargs: {right: ..., on: ..., how: ...}", info: "Merge another table (specify destination id). Right cannot depend on other tables (no nested merging)"},
    ].map(
      (o) => ({...o, apply: (match.groups!['spaceAfterColon']?'':' ') + o.label})
    ))
  } else if (isRoot) {
    switch (key) {
      case 'refresh':
      case 'poach_city': {
        options.push(...[
          { label: 'true', type: "text", info: "Yes" },
          { label: 'false', type: "text", info: "No" },
        ])
        break
      }
      case 'cama': {
        options.push(...[
          { label: '"gsa"', type: "text", info: "Government Software Assurance" },
          { label: '"ltc"', type: "text", info: "Louisiana Tax Commish" },
          { label: '"pacs"', type: "text", info: "PACS" },
          { label: '"devnet"', type: "text", info: "DevNet" },
          { label: '"tyler"', type: "text", info: "Tyler Technologies" },
          { label: '"i3"', type: "text", info: "i3" },
        ])
        break
      }
      case 'd2p': {
        options.push(
          { label: '"concat(\'HTTP_URL_PREFIX\', parcel_num)"', type: "text", info: "Default D2P link template" },
        )
        break
      }
      case 'customer_id': {
        if (currentCustomer.value) {
          options.push(
            { label: `"${state.formValues.customer_id}"`, type: "text", info: `Selected customer (${currentCustomer.value?.name})` },
          )
        }
        break
      }
      case 'county_display_name': {
        if (currentCustomer.value?.name) {
          const nameWithoutState = currentCustomer.value.name.replace(/, [A-Z]{2}$/, '')
          options.push(
            { label: `"${nameWithoutState}"`, type: "text", info: "Selected customer" },
          )
        }
        break
      }
      case 'fips_code': {
        if (currentCustomer.value?.fips_code) {
          options.push(
            { label: `"${currentCustomer.value.fips_code}"`, type: "text", info: `(${currentCustomer.value.name})` },
          )
        }
        break
      }
      case 'schema_name': {
        if (currentCustomer.value?.schema_name) {
          options.push(
            { label: `"${currentCustomer.value.schema_name}"`, type: "text", info: `(${currentCustomer.value.name})` },
          )
        }
        break
      }
      case 'state_abbrev': {
        if (currentCustomer.value?.state) {
          options.push(
            { label: `"${currentCustomer.value.state.toUpperCase()}"`, type: "text", info: `(${currentCustomer.value.name})` },
          )
          break
        }
        const stateLabelValues = [
          { label: 'Alabama', value: 'AL' },
          { label: 'Alaska', value: 'AK' },
          { label: 'American Samoa', value: 'AS' },
          { label: 'Arizona', value: 'AZ' },
          { label: 'Arkansas', value: 'AR' },
          { label: 'California', value: 'CA' },
          { label: 'Colorado', value: 'CO' },
          { label: 'Connecticut', value: 'CT' },
          { label: 'Delaware', value: 'DE' },
          { label: 'District of Columbia', value: 'DC' },
          { label: 'Florida', value: 'FL' },
          { label: 'Georgia', value: 'GA' },
          { label: 'Guam', value: 'GU' },
          { label: 'Hawaii', value: 'HI' },
          { label: 'Idaho', value: 'ID' },
          { label: 'Illinois', value: 'IL' },
          { label: 'Indiana', value: 'IN' },
          { label: 'Iowa', value: 'IA' },
          { label: 'Kansas', value: 'KS' },
          { label: 'Kentucky', value: 'KY' },
          { label: 'Louisiana', value: 'LA' },
          { label: 'Maine', value: 'ME' },
          { label: 'Maryland', value: 'MD' },
          { label: 'Massachusetts', value: 'MA' },
          { label: 'Michigan', value: 'MI' },
          { label: 'Minnesota', value: 'MN' },
          { label: 'Mississippi', value: 'MS' },
          { label: 'Missouri', value: 'MO' },
          { label: 'Montana', value: 'MT' },
          { label: 'Nebraska', value: 'NE' },
          { label: 'Nevada', value: 'NV' },
          { label: 'New Hampshire', value: 'NH' },
          { label: 'New Jersey', value: 'NJ' },
          { label: 'New Mexico', value: 'NM' },
          { label: 'New York', value: 'NY' },
          { label: 'North Carolina', value: 'NC' },
          { label: 'North Dakota', value: 'ND' },
          { label: 'Ohio', value: 'OH' },
          { label: 'Oklahoma', value: 'OK' },
          { label: 'Oregon', value: 'OR' },
          { label: 'Pennsylvania', value: 'PA' },
          { label: 'Puerto Rico', value: 'PR' },
          { label: 'Rhode Island', value: 'RI' },
          { label: 'South Carolina', value: 'SC' },
          { label: 'South Dakota', value: 'SD' },
          { label: 'Tennessee', value: 'TN' },
          { label: 'Texas', value: 'TX' },
          { label: 'Utah', value: 'UT' },
          { label: 'Vermont', value: 'VT' },
          { label: 'Virgin Islands', value: 'VI' },
          { label: 'Virginia', value: 'VA' },
          { label: 'Washington', value: 'WA' },
          { label: 'West Virginia', value: 'WV' },
          { label: 'Wisconsin', value: 'WI' },
          { label: 'Wyoming', value: 'WY' },
        ]
        options.push(...stateLabelValues.map(
          (o) => ({
            label: `"${o.value}"`,
            type: "text",
            detail: o.label,
          })
        ))
        break
      }
    }
  } else if (isExemption) {
    switch (key) {
      case "low_income":
      case "is_pre": {
        options.push(...[
          { label: 'true', type: "text", info: "Yes" },
          { label: 'false', type: "text", info: "No" },
        ])
        break
      }
      case "code": {
        options.push(...[
          { label: '"HS"', type: "text", info: "Homestead" },
          { label: '"DV"', type: "text", info: "Disabled Veteran" },
          { label: '"SS"', type: "text", info: "Surviving Spouse" },
          { label: '"DP"', type: "text", info: "Disabled Person" },
          { label: '"SF"', type: "text", info: "Senior Freeze" },
        ])
        break
      }
      case "category": {
        options.push(...[
          { label: '"homestead"', type: "text", info: "Homestead" },
          { label: '"active_duty"', type: "text", info: "Active Duty" },
          { label: '"veteran"', type: "text", info: "Veteran" },
          { label: '"disabled"', type: "text", info: "Disabled" },
          { label: '"disabled_veteran"', type: "text", info: "Disabled Veteran" },
          { label: '"widow"', type: "text", info: "Widow" },
          { label: '"senior"', type: "text", info: "Senior" },
          { label: '"senior_freeze"', type: "text", info: "Senior Freeze" },
          { label: '"senior_longterm"', type: "text", info: "Senior Longterm" },
          { label: '"exempt_org"', type: "text", info: "Exempt Organization" },
          { label: '"homestead_cap"', type: "text", info: "Homestead Cap" },
          { label: '"other"', type: "text", info: "Other" },
        ])
        break
      }
    }
  } else if (isDataset) {
    // id based on key
    switch (key) {
      case "id": {
        const s3_key = currentObj.key ? currentObj.key.value.replaceAll('"', '') : null
        if (s3_key) {
          const datasetId = s3_key.split('/').slice(-1)
          options.push(
            { label: `"${datasetId}"`, type: "text", info: "Dataset ID" },
          )
        }
      }
    }
  } else if (isDestination) {
    switch (key) {
      case "destination": {
        options.push(...[
          { label: '"parcels"', type: "text", info: "Parcels" },
          { label: '"owners"', type: "text", info: "Owners" },
          { label: '"exemptions"', type: "text", info: "Exemptions" },
          { label: '"transactions"', type: "text", info: "Transactions" },
          { label: '"addresses"', type: "text", info: "Addresses" },
        ])
        break
      }
      case "transform": {
        options.push(formattedObjectOption(
          [], match?.groups?.indent || "", "Transform pipeline", '[', false
        ))
        break
      }
    }
  } else if (isJoin) {
    switch (key) {
      case "how": {
        options.push(...[
          { label: '"left"', type: "text", info: "Left join" },
          { label: '"inner"', type: "text", info: "Inner join" },
        ])
        break
      }
      case "on":
      case "left_on":
        options.push(...[
          { label: '"pin"', type: "text", info: "Primary PIN" },
          { label: '"pin2"', type: "text", info: "Secondary PIN" },
        ])
        break
    }
  } else if (isPandasKwargs) {
    switch (key) {
      case "sep": {
        options.push(...[
          { label: '","', type: "text", info: "Comma" },
          { label: '"\\t"', type: "text", info: "Tab" },
          { label: '"|"', type: "text", info: "Pipe" },
          { label: '";"', type: "text", info: "Semicolon" },
        ])
        break
      }
      case "quotechar": {
        options.push(...[
          { label: '"\\""', type: "text", info: "Double quote" },
          { label: '"~""', type: "text", info: "Tilda" },
        ])
        break
      }
      case "encoding": {
        options.push(...[
          { label: '"utf-8"', type: "text", info: "UTF-8" },
          { label: '"latin-1"', type: "text", info: "Latin-1" },
          { label: '"cp1252"', type: "text", info: "CP1252" },
          { label: '"ISO-8859-1"', type: "text", info: "ISO-8859-1" },
          { label: '"utf-16"', type: "text", info: "UTF-16" },
          { label: '"windows-1250"', type: "text", info: "Windows-1250" },
        ])
        break
      }
    }
  } else if (!isFunctionName && isMappingArgsContext) {
    // {function: mapping, args: [{foo: {function: ..., args: [{bar: <--- we're here, right after the colon
    options.push(formattedObjectOption({ function: "", args: []}, match?.groups?.indent || "", "Function boilerplate (inner function call)", '"function": "', true, (hasRightmostParams ? ',' : '')))
    options.push(
      ...columnNameOptions(hasRightmostParams? ',': '')
    )
  } else if (isAggregateRule) {
    options.push(...[
      '"sum"', '"min"', '"max"', '"mean"', '"first"', '"last"', '"concat"'
    ].map(a => ({
      label: a,
      type: "text",
      detail: '(aggregation function )',
    })))
  }

  return {
    from: replFrom,
    to: replTo,
    options,
    filter: !['""', '"'].includes(repl)
  }
}

const extensions = [
  json(),
  linter(jsonParseLinter()),
  linter(configLinter),
  lintGutter(),
  autocompletion({
    override: [
      objectParamValueCompletions,
      objectParamNameCompletion,
      arrayValueCompletions,
    ],
    closeOnBlur: true,
    activateOnTyping: true,
  }),
  
]

const view = shallowRef()
const handleReady = (payload: any) => {
  view.value = payload.view
}
const breadcrumbs = ref([] as any[])
const requestedColumns = ref([] as string[])

const handleUpdate = () => {
  if (!view.value) return
  breadcrumbs.value = codemirrorState().breadcrumbs
  requestColumnsForAllDatasets()
}

const requestColumnsForAllDatasets = () => {
  const datasets = parseAllDatasetKeys()
  for (const uri of datasets) {
    if (!requestedColumns.value.includes(uri)) {
      requestedColumns.value.push(uri)
      cache.getTableDataInfo(uri)
    }
  }
}

const parseAllDatasetKeys = () => {
  const tree = view.value.state.tree
  const jsontext = tree.topNode
  const rootObj = jsontext.firstChild
  const datasetsArray = getNodeObjectParamValueNode(rootObj, "datasets")?.lastChild
  const datasetKeys = [] as string[]
  for (const ds of getAllChildren(datasetsArray)) {
    const key = getNodeObject(ds)?.key?.value?.replace(/(^"|"$)/g, '')
    if (key) {
      datasetKeys.push(key)
    }
  }
  return datasetKeys
}

const jumpTo = (node: any) => {
  view.value!.dispatch({
    selection: {
      anchor: node.from + 1,
      head: node.to - 1,
    },
    scrollIntoView: true,
  })
}

const getNodeText = (nd: any) => {
  if (!nd || !view.value) return null
  let res = view.value?.state.doc.sliceString(nd.from, nd.to)
  if (["Object", "Array"].includes(nd.name)) {
    res = res.replace(/\s+/g, " ")
  }
  return res
}

const getNodeObject = (nd: any): any => {
  const obj: any = {}
  nd.getChildren("Property").forEach(
    (p: any) => {
      obj[getNodeText(p.firstChild).slice(1, -1)] = {
        value: getNodeText(p.firstChild.nextSibling),
        node: p
      }
    }
  )
  return obj
}

const getNodeObjectParamValueNode = (nd: any, paramName: string): any => {
  const obj = getNodeObject(nd)
  const param = obj[paramName]
  if (!param) return null
  return param?.node
}

const codemirrorState = () => {
  const state = view.value!.state
  const ranges = state.selection.ranges
  const selected = ranges.reduce(
    (r: number, range: {to: number, from: number}) => r + range.to - range.from,
    0
  )
  const cursor = ranges[0].anchor
  const length = state.doc.length
  const lines = state.doc.lines
  const getBreadcrumbsByNode = (nd: any) => {
    const breadcrumbs = []
    let breadcrumbIndex = 0
    let prevNode = null;
    let prevProperty = null
    while (nd) {
      const bc = (() => {
        switch (nd.name) {
          case "String":
          case "Number":
          case "True":
          case "False":
          case "Null":
            return {
              node: nd,
              text: getNodeText(nd),
              type: nd.name,
              parentKey: null as string | null,
              parentIx: null as number | null,
              breadcrumbIndex,
            }
          case "PropertyName":
            return null
          case "Property": {
            const propertyName = getNodeText(nd.firstChild).slice(1, -1)
            const propertyValue = getNodeText(nd.lastChild)
            prevProperty =  {
              node: nd,
              text: `.${propertyName}`,
              name: propertyName,
              value: propertyValue,
            }
            return null
          }
          case "Object": {
            const obj: any = getNodeObject(nd)
            
            // const reprProp = (prop: any) => `"${prop.prop}": ...`
            const repr = (prevNode && prevNode.name == "Property" && prevProperty) ? prevProperty.text : '{ ... }'
            if (breadcrumbs.length > 0) {
              breadcrumbs[breadcrumbs.length - 1].parentKey = prevProperty?.name
            }
            return {
              node: nd,
              text: repr,
              type: nd.name,
              obj,
              prop: prevProperty,
              breadcrumbIndex,
            }
          }
          case "Array": {
            const arr = []
            let child = nd.firstChild
            let prevNodeIx = null
            while (child) {
              if (!JsonNodeNames.includes(child.name)) {
                child = child.nextSibling
                continue
              }
              arr.push({node: child, text: getNodeText(child)})
              if (prevNode && prevNode.index === child.index && prevNode.from === child.from) {
                prevNodeIx = arr.length - 1
                if (breadcrumbs.length > 0) {
                  breadcrumbs[breadcrumbs.length - 1].parentIx = prevNodeIx
                }
              }
              child = child.nextSibling
            }
            return {
              node: nd,
              text: prevNodeIx !== null ?`[${prevNodeIx}]`:'[]',
              type: nd.name,
              arr,
              ix: prevNodeIx,
              itemNode: prevNode,
              breadcrumbIndex,
            }
          }
          case "JsonText": { // root
            return null
          }
          case "⚠": { // json parser error node
            return null
          }
          default: {
            throw new Error(`Unknown node type: ${nd.name}`)
          }
        }
      })()
      if (bc) {
        breadcrumbs.push(bc)
        breadcrumbIndex += 1
      }
      prevNode = nd
      nd = nd.parent
    }
    
    return breadcrumbs.reverse()
  }
  const tree = state.tree
  const nd = tree.resolveInner(ranges[0].anchor)
  const breadcrumbs = getBreadcrumbsByNode(nd)
  return {
    selected,
    cursor,
    length,
    lines,
    breadcrumbs,
    tree
  }
}

const getCustomers = async () => {
  state.customersLoading = true;
  const response = await api.get(`customers/`);
  state.customers = response.data;
  state.customersLoading = false;
}

const getCustomerById = (id: string | number): Customer | undefined => {
  return state?.customers?.find(customer => customer.customer_id == id)
}

const loadConfig = async () => {
  const id = router.currentRoute.value.params.id
  if (newConfig.value) {
    return
  }
  state.configLoading = true;
  const response = await api.get(`onboarding/configs/${id}`);
  state.config = response.data;
  state.configLoading = false;
  state.formValues = {
    customer_id: state.config?.customer_id || '',
    name: state.config?.name || null,
    body: JSON.stringify(
      // hack to sort keys inside objects
      Object.keys(state.config?.body || {}).sort().reduce((r: any, k: any) => (r[k] = state.config?.body[k], r), {}),
      null,
      2
      // 2
    ),
  }
}

const saveConfig = async () => {
  const body = JSON.parse(state.formValues.body)
  if (newConfig.value) {
    const created = await createConfig(
      {
        customer_id: state.formValues.customer_id,
        name: state.formValues.name,
        body,
      }
      
    )
    await router.push({
      name: 'onboardingConfig',
      params: {
        id: created.id!,
      }
    })
    await loadConfig()
    toast.success('Created new data config')
  } else if (state.config) {
    await updateConfig(
        state.config.id!,
        {
          body,
          name: state.formValues.name,
          comment: state.formValues.comment,
        }
    )
    await loadConfig()
    toast.success('Updated data config')
  }
}


const createConfig = async (data: CreateConfig): Promise<Config> => {
  try {
    state.configLoading = true
    const resp = await api.post(`onboarding/configs/`, data)
    return resp.data
  } catch (e: any) {
    toast.error(e?.message || "Error creating config")
    throw e
  } finally {
    state.configLoading = false
  }
}

const updateConfig = async (id: string, data: UpdateConfig): Promise<Config> => {
  try {
    state.configLoading = true
    const resp = await api.post(`onboarding/configs/${id}`, data)
    return resp.data
  } catch (e: any) {
    toast.error(e?.message || "Error updating config")
    throw e
  } finally {
    state.configLoading = false
  }
}

const runConfig = async () => {
  await router.push({
    name: 'ingest',
    params: {
      id: 'new',
    }
  })
}

onMounted(async () => {
  await Promise.all([
    getCustomers(),
    loadConfig()
  ])
  hidePFGetHelpButton()
});
</script>

<style scoped>
.breadcrumb-span {
  cursor: pointer;
  margin-right: 5px;
}
.breadcrumb-span:hover {
  color: #0d6efd;
  text-decoration: underline;
}

.breadcrumbs-panel {
  position: sticky; bottom: 0;
  z-index: 1;
  background-color: white;
}
</style>
