<template>
    <div class="">
        <div class="modal-dialog" v-if="!loadingState">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="modal-title">
                        <h5 v-if="omnisearch.parcel_num"><b>{{ omnisearch.address }}</b></h5>
                        <span v-else>-</span>
                    </div>
                </div>
                <hr>
                <div class="modal-body" v-if="!loadingState">
                    <table class="table table-striped" v-if="omnisearch.parcel_num">
                        <tbody>
                            <tr v-for="si in omnisearchFormatted" :key="si.key">
                                <td class="key">{{ si.key }}</td>
                                <td class="value">
                                    <div v-if="si.key === 'Situs County'">
                                        <a target="_blank" :href="omnisearch.county_lookup_url">{{ si.value }}</a>
                                    </div>
                                    <div v-else-if="si.key === 'Mail County'">
                                        <a target="_blank" :href="omnisearch.mail_county_lookup_url">{{ si.value }}</a>
                                    </div>
                                    <div v-else-if="si.key === 'Parcel Lookup'">
                                        <a target="_blank" :href="omnisearch.d2p_url">Direct-2-Parcel™ Link</a>
                                    </div>
                                    <div v-else-if="si.key === 'Assessed Value' || si.key == 'Market Value'">
                                        {{
                                            isNaN(si.value as number)
                                                ? si.value
                                                : parseFloat(si.value as string).toLocaleString("en-US", {
                                                    style: "currency",
                                                    currency: "USD"
                                                })
                                        }}
                                    </div>
                                    <div v-else-if="si.key === 'Delivery Point Details'">
                                        <div v-for="voters in si.value" :key="voters">
                                            <label class="mt-1">{{ voters }}</label>
                                        </div>
                                    </div>
                                    <div v-else-if="si.key === 'MLS Sales'">
                                        <div v-for="voters in si.value" :key="voters">
                                            <label class="mt-1">{{ voters }}</label>
                                        </div>
                                    </div>
                                    <div v-else-if="si.key === 'Registered Voters'">
                                        <div v-for="voters in si.value" :key="voters">
                                            <label class="mt-1">{{ voters }}</label>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <span v-if="dayjs(si.value as string).isValid()">
                                            {{ cleanDate(si.value as string) }}
                                        </span>
                                        <span v-else>
                                            {{ si.value }}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div v-else>
                        No information could be found regarding this address
                    </div>
                </div>
                <div class="modal-footer">
                    <button @click="closePopup" style="border: 1px solid #ccc" class="btn btn-default">Close</button>
                </div>
            </div>
        </div>
        <loading-icon v-else />
    </div>
</template>

<script setup lang="ts">
import type { AxiosError } from 'axios';
import LoadingIcon from './LoadingIcon.vue';
import { onMounted, computed, ref } from 'vue';
import { toast } from '@/helpers/toast';
import type { Omnisearch } from '@/helpers/interface/candidates';
import { useCandidateDetailsStore } from "@/stores/candidateDetails";
import { cleanDate, formatProperties, getApiErrorMessage } from '@/helpers/common';
import dayjs from 'dayjs';

const store = useCandidateDetailsStore()
const props = defineProps({
    ulID: {
        type: String,
    }
})

const omnisearchPrettyNames = {
    "owner": "Owner",
    "address": "Situs Address",
    "county": "Situs County",
    "parcel_num": "Parcel Number",
    "exemptions": "Exemptions",
    "d2p_url": "Parcel Lookup",
    "parcel_num_alt": "Alt. Parcel Number",
    "mail_address": "Mail Address",
    "mail_county": "Mail County",
    "prop_use": "Property Use",
    "val_assessed": "Assessed Value",
    "val_market": "Market Value",
    "deed_date": "Last Sold",
    "sold": "MLS Sales",
    "voter": "Registered Voters",
    "dpv": "Delivery Point Details",
}
const emits = defineEmits(['closePopup'])
const closePopup = () => emits("closePopup", false)
const omnisearch = computed(() => (store.getOmnisearch as Omnisearch))
const omnisearchFormatted = computed(() => {
    let formatted = formatProperties(omnisearch.value, omnisearchPrettyNames)
    formatted = formatted.filter((field: any) => {
        if (
            (field.key === "Registered Voters" && field.value !== null) ||
            (field.key === "MLS Sales" && field.value !== null) ||
            (field.key === "Delivery Point Details" && field.value !== null)
        ) {
            field.value = field.value.split("\n")
            return field
        }
        if (field.value !== null) return field
    });
    return formatted
})
const loadingState = ref(true)
const loadFailureHandler = (error: AxiosError) => {
    const message = getApiErrorMessage(error)
    toast.error(message)
}

onMounted(async () => {
    await store.fetchOmnisearchInfo((props.ulID as string), loadFailureHandler)
    loadingState.value = false
})
</script>
