<template>
	<div id="expired-observations-list"></div>
	<teleport to="body">
		<div class="modal-overlay" v-if="notificationVisibility.showNotification" @click="toggleNotification"></div>
		<OmnisearchPopUp @closePopup="toggleNotification" class="notification" :ulID="selectedUlID"
			v-if="notificationVisibility.showNotification" />
	</teleport>
</template>

<script setup lang="ts">
import dayjs from "dayjs"
import { ref, watch, onBeforeUnmount, onMounted, onUnmounted, computed } from 'vue';
import type { TabulatorFull as Tabulator } from 'tabulator-tables'; //import Tabulator library
import type { ColumnDefinition, CellComponent, } from "tabulator-tables";
import OmnisearchPopUp from "@/components/Shared/OmnisearchPopUp.vue";
import type { FlagsAPIResponse } from "@/helpers/interface/candidates"
import { createTabulator, destroyTabulator } from "@/helpers/true-tabulator"
import { useCandidateDetailsStore } from "@/stores/candidateDetails";
import { sanitizeHTML } from "@/helpers/common";

const store = useCandidateDetailsStore();
const expired_observations = computed((): FlagsAPIResponse[] => [...store.getExpiredFlags, ...store.getExpiredNotes])
const selectedUlID = ref<string>("")
const notificationVisibility = ref({ showNotification: false })
const toggleBodyScrolling = (allowScrolling: boolean) => document.body.style.overflow = allowScrolling ? 'auto' : 'hidden';
const toggleNotification = () => {
	notificationVisibility.value.showNotification = notificationVisibility.value.showNotification ? false : true
	toggleBodyScrolling(!notificationVisibility.value.showNotification);
}
onBeforeUnmount(() => toggleBodyScrolling(true))

let tabulator: Tabulator | null
const tableColumns = ref<ColumnDefinition[]>([
	{ title: "Expired", field: "name", visible: true, width: "20%", formatter: "textarea", headerSort: true, headerWordWrap: true},
	{
		title: "Details",
		field: "display_html",
		width: "60%",
		visible: true,
		headerSort: true,
		formatter: function (cell: CellComponent) {
			const data = cell.getData() as FlagsAPIResponse;
			let display_html = data.display_html?.trim() || ""
			// if this is a user-created flag, append the user who created it
			if (!data.observation_id) {
				const userName = sanitizeHTML(data.created_by)
				display_html += `<div class="user-flag-source mt-2">Source of information: <span class="text-capitalize">${userName}</span></div>`
			}
			return display_html
		}
	},
	{
		title: "Date",
		field: "event_date",
		width: "20%",
		visible: true,
		headerSort: true,
		formatter: function (cell: CellComponent) {
			return dayjs(cell.getValue()).format("M/D/YYYY")
		}
	}
])

const loadTabulator = async (data: FlagsAPIResponse[] | undefined) => {
	tabulator = await createTabulator("#expired-observations-list", {
		data: data,
		columns: tableColumns.value,
		initialSort: [
			{ column: "event_date", dir: "desc" },
		],
		pagination: false,
		placeholder: "No records found.",
	})

	setTimeout(() => {
		const unitLevelElement = document.querySelectorAll<HTMLTableCellElement>('.card-cl-flags #expired-observations-list td[unit_level_id]')
		unitLevelElement.forEach((td: HTMLTableCellElement) => {
			const ulID = td.getAttribute("unit_level_id") as string
			if (!ulID || ulID === "None") return
			const text = td.innerText
			const popupAnchor = document.createElement("a") as HTMLAnchorElement
			popupAnchor.innerText = text
			popupAnchor.href = "#"
			popupAnchor.addEventListener("click", (event) => {
				event.preventDefault()
				selectedUlID.value = ulID as string
				toggleNotification()
			})
			td.innerHTML = ""
			td.appendChild(popupAnchor)
		})
	}, 1000)
}
onMounted(() => loadTabulator(expired_observations.value))
onUnmounted(() => destroyTabulator(tabulator))
watch(() => expired_observations.value, async (data) => {
	await loadTabulator(data)
})
</script>
