<template>
  <TheHeader/>
  <main class="p-2">
    <SidebarNavigation :menus="sidebarMenus" :collapsed="isCollapsed" :activeMenu="activeMenu"
        :key="activeMenu" @menuSelected="handleMenuSelected" @collapseChanged="handleCollapsed" />
    <div :class="['sidebar-content-wrapper', { 'sidebar-collapsed': isCollapsed }]">
        <div class="sidebar-content">
            <Card v-if="activeMenu === 'configs'">
                <template #content>
                    <configList/>
                </template>
            </Card>
            <Card v-if="activeMenu === 'customer_configs'">
                <template #content>
                    <customerConfigs/>
                </template>
            </Card>
            <Card v-if="activeMenu === 'ingestions'">
                <template #content>
                    <ingestions/>
                </template>
            </Card>
            <Card v-if="activeMenu === 'data_requests'">
                <template #content>
                    <dataRequests />
                </template>
            </Card>
            <Card v-if="activeMenu === 'scorecards'">
                <template #content>
                    <scorecards />
                </template>
            </Card>
            <Card v-if="activeMenu === 'background_jobs'">
                <template #content>
                    <backgroundJobs />
                </template>
            </Card>
        </div>
    </div>
  </main>
</template>
<script setup lang="ts">
import {ref} from 'vue';
import {setPageTitle} from "@/helpers/common";

import backgroundJobs from '@/components/Admin/Onboarding/Onboarding-backgroundJobs.vue';
import configList from '@/components/Admin/Onboarding/Onboarding-configList.vue';
import customerConfigs from '@/components/Admin/Onboarding/Onboarding-customerConfigs.vue'
import ingestions from '@/components/Admin/Onboarding/Onboarding-ingestionList.vue';
import dataRequests from '@/components/Admin/Onboarding/Onboarding-dataRequestList.vue';
import scorecards from '@/components/Admin/Onboarding/Onboarding-scorecardViewer.vue'
import TheHeader from "@/components/TheHeader.vue";
import type {SidebarNavigationMenu} from "@/helpers/interface/candidates";
import SidebarNavigation from "@/components/Shared/SidebarNavigation.vue";
import Card from "primevue/card";

const isCollapsed = ref(false)

const handleCollapsed = (collapsed: boolean) => isCollapsed.value = collapsed

const activeMenu = ref('background_jobs')
const sidebarMenus = ref<SidebarNavigationMenu[]>([
  { label: "Config List", iconClass: "fa-solid fa-scroll", id: "configs" },
  { label: "Customer Configs", iconClass: "fa-regular fa-address-book", id: "customer_configs" },
  { label: "Ingestions", iconClass: "fa-solid fa-rotate-right", id: "ingestions" },
  { label: "Data Requests", iconClass: "fa-solid fa-file-arrow-down", id: "data_requests" },
  { label: "Client Reports", iconClass: "fa-solid fa-file-circle-check", id: "scorecards" },
  { label: "Onboarding Tools", iconClass: "fa-solid fa-screwdriver-wrench", id: "background_jobs" },
])
const handleMenuSelected = (menuId: string) => {
  setPageTitle(sidebarMenus.value.find((menu) => menu.id === menuId)?.label || 'Onboarding Tools')
  activeMenu.value = menuId
}
</script>