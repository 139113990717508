<template>
  <div ref="DataRequestDataView" id="DataRequestDataView">
    <div class="row action-toolbar-container" id="page-header-action-section">
      <div class="col-6" v-if="!loading">
        <Button type="button" class="me-2" @click="SendToWindow($router.resolve({ name: 'dataRequest', params: { data_request_id: 'new' }}).href)" severity="info">
          <span class="fa fa-plus-circle" aria-hidden="true"></span> New
        </Button>
        <Button type="button" :disabled="(selectedItems.length <= 0)" class="me-2" @click="deleteDataRequests()" severity="danger">
          <span class="fa fa-trash" aria-hidden="true"></span> Delete
        </Button>
        <Button type="button" :disabled="(selectedItems.length <= 0)" class="me-2" @click="hideDataRequests()" severity="warning">
          <span class="fa fa-archive" aria-hidden="true"></span> Hide
        </Button>
        <Button type="button" :disabled="(selectedItems.length <= 0)" class="me-2" @click="cloneDataRequests()" severity="success">
          <span class="fa fa-layer-group" aria-hidden="true"></span> Clone
        </Button>
      </div>
      <div class="col-4" v-else>
        <Skeleton width="400px" height="34px" class="ms-2 mb-2"></Skeleton>
      </div>
      <div class="col-2" v-if="!loading">
        <input type="text"  id="customerInput" class="form-control" placeholder="Data Request Search" v-model="searchField">
      </div>
      <div class="col-2" v-if="!loading">
        <Button v-if="searchField" type="button" class="me-2" @click="search = searchField" severity="secondary">
          <span class="fa fa-solid fa-magnifying-glass" aria-hidden="true"></span>
        </Button>
        <Button v-if="search" type="button" class="me-2" @click="search = ''; searchField = ''" severity="secondary">
          <span class="fa fa-solid fa-circle-xmark" aria-hidden="true"></span>
        </Button>
      </div>
      <div class="col-2" v-else>
        <Skeleton width="225px" height="34px" class="ms-2 mb-2"></Skeleton>
      </div>
      <div class="ms-auto" style="width: fit-content;" v-if="!loading">
        <div>
          <Checkbox v-model="showHidden" :input-id="'showHidden'" binary/>
          <label for="showHidden">Show Hidden</label>
        </div>
        <div>
          <Checkbox v-model="showAutomated" :input-id="'showAutomated'" binary/>
          <label for="showAutomated">Show Automated</label>
        </div>
        <MultiSelect :show-toggle-all="false" required v-model="statusFilters" multiple :options="statuses" class="d-flex">
          <template #value>
            <span>Filter Status</span>
          </template>
        </Multiselect>
      </div>
      <div class="ms-auto" style="width: fit-content;" v-else>
        <Skeleton width="150px" height="16px" class="ms-2 mb-2"></Skeleton>
        <Skeleton width="150px" height="16px" class="ms-2 mb-2"></Skeleton>
        <Skeleton width="150px" height="22px" class="ms-2 mb-2"></Skeleton>
      </div>
    </div>

    <div v-if="loading">
      <table class="table table-striped">
        <tbody>
          <tr>
            <td colspan="6" style="text-align: center">
              <div class="spinner-border" style="width: 100px; height: 100px;" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <BasicStrapCards :loading="loading" :parent-data-view="DataRequestDataView" :total-records="totalRecords" :rows="rows" :first-index="first" :data="data" @onpage="(event) => onPage(event)" :key="reloader">
      <template #list="slotProps">
        <div v-for="(item, index) in (slotProps.data as DataRequest[])" :key="index" class="row dataview-list-row-card" :id="`row_${index}`">
          <div class="col-1 align-content-center" id="selectionColumn">
            <div class="p-3">
              <Checkbox v-model="selectedItems" :inputId="item.id" name="category" :value="item.id" />
            </div>
          </div>
          <div class="col align-content-center clipboard-copy-wrapper">
            <div class="p-3">
              <div class="mb-2">
                {{ item.title }}
              </div>
              <div class="mb-2">
                <a :href="'/data-requests/' + item.id" target="_blank">
                  Edit
                </a>
                |
                <a :href="'/data-drop/' + item.id" target="_blank">
                  View
                </a>
                |
                <a href="#" @click="copyLink(getDataRequestUrl(item))" style="">
                  Copy
                </a>
              </div>
            </div>
          </div>
          <div class="col align-content-center">
            <div class="mb-2">
              {{ getCustomer(item.customer_id || 'Invalid Customer ID') }}
            </div>
            <div class="mb-2">
              Request Type: {{ item.request_type }}
            </div>
            <div class="mb-2">
              Customer ID: {{ item.customer_id }}
            </div>
          </div>
          <div class="col align-content-center">
            <div class="mb-2">
              Created: {{ new Date(item.created_at).toLocaleDateString() }}
            </div>
            <div class="mb-2">
              Updated: {{ new Date(item.updated_at).toLocaleDateString() }}
            </div>
          </div>
          <div class="col align-content-center">
            <div class="mb-2">
              Status: {{ item.status.toLocaleLowerCase().replace(/\b\w/g, (char) => char.toUpperCase()) }}
            </div>
          </div>
        </div>
      </template>
    </BasicStrapCards>
  </div>
</template>

<script setup lang="ts">
import { useAPI } from "@/helpers/services/api"
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import {reactive, onMounted, ref, watch, computed} from "vue";
import BasicStrapCards from "@/components/Shared/BasicStrapCards.vue"
import type {
  DataRequest,
  LightCustomer,
  NewDataRequest,
  NewDataRequestItem,
} from "@/helpers/interface/trueload";
import type {
  DataTableEvent
} from "@/helpers/interface/general"
import { toast } from "@/helpers/toast";
import {
  getApiErrorMessage,
  hidePFGetHelpButton,
  scrollToView,
} from "@/helpers/common";
import Checkbox from "primevue/checkbox";
import Button from "primevue/button";
import MultiSelect from "primevue/multiselect";
import Skeleton from "primevue/skeleton";

const state = reactive({
  customers: [] as Array<LightCustomer>,
})

const statuses = ["pending", "completed", "cancelled", "deleted"]

const DataRequestDataView = ref<HTMLElement | null>(null);
const loading = ref(false);
const first = ref(0);
const rows = ref(25);
const data = ref<DataRequest[]>([]);
const totalRecords = ref(0);
const currentPage = ref(1);
const dataParams = ref<DataTableEvent | null>(null);
const reloader = ref(0)

const selectedItems = ref<string[]>([]);
const statusFilters = ref<string[]>(["deleted"]);

const showHidden = ref<boolean>(false);
const showAutomated = ref<boolean>(false);

const searchField = ref<string>("")
const search = ref<string>("")

const emits = defineEmits(["onpage"])

watch([showHidden, showAutomated, statusFilters, search], () => {
  first.value = 0;
  onPage({page: 0, first: 0, rows: rows.value});
  reloader.value += 1;
})


const deleteDataRequests = async () => {
  loading.value = true
  for (const id of selectedItems.value) {
    try {
      await api.patch(`data-requests/${id}`, {'status': 'deleted'})
    }
    catch (error: any) {
      toast.error(getApiErrorMessage(error))
    }
  }
  selectedItems.value = []
  await loadData({page: currentPage, rows: rows})
  loading.value = false
}

const cloneDataRequests = async () => {
  loading.value = true
  for (const id of selectedItems.value) {
    try {
      const dr_response = await api.get(`data-requests/${id}`)
      const cloned_dr = {
        title: dr_response.data.title,
        customer_id: dr_response.data.customer_id,
        recipient_emails: dr_response.data.recipient_emails,
        osm_emails: dr_response.data.osm_emails,
        request_type: dr_response.data.request_type,
        description: dr_response.data.description,
        items: dr_response.data.items as Array<NewDataRequestItem>
      } as NewDataRequest
      await api.post(`data-requests/`, cloned_dr)
    }
    catch (error: any) {
      toast.error(getApiErrorMessage(error))
    }
  }
  selectedItems.value = []
  await loadData({page: currentPage, rows: rows})
  loading.value = false
}

const hideDataRequests = async () => {
  loading.value = true
  for (const id of selectedItems.value) {
    try {
      await api.patch(`data-requests/${id}`, {'hidden': 'true'})
    }
    catch (error: any) {
      toast.error(getApiErrorMessage(error))
    }
  }
  selectedItems.value = []
  await loadData({page: currentPage, rows: rows})
  loading.value = false
}

const getDataRequestUrl = (dataRequest: DataRequest): string => {
  return `${window.location.protocol}//${window.location.host}/data-drop/${dataRequest.id}`
}

const copyLink = (dataRequestUrl: string) => {
  navigator.clipboard.writeText(dataRequestUrl)
  toast.success('Link copied to clipboard')
}

const getCustomer = (customer_id: string) => {
  return state.customers.find(item => item.customer_id === customer_id)?.name || "Customer Not Found"
}

const api = useAPI()

const onPage = async (event: DataTableEvent) => {
    event.page += 1
    dataParams.value = event
    currentPage.value = event.page
    await loadData(event)
    scrollToView("row_0")
}

const loadData = async (event: any = null) => {
    loading.value = true
    dataParams.value = { ...dataParams.value, page: event?.page || 1, rows: event?.rows || 25 }
    let payload = configParams(dataParams.value)

    payload.page = currentPage.value

    const requests = []

    const dr_filter = {
      "limit": parseInt(payload.page_size),
      "offset": (parseInt(payload.page_size) * (parseInt(payload.page) - 1)),
      "status_filter": statusFilters.value,
      "show_hidden": showHidden.value,
      "show_automated": showAutomated.value,
      "search": search.value,
    }
    const listRequest = api.post(`/data-requests/list`, dr_filter)
        ?.then(response => {
            data.value = response.data || []
        })
        ?.catch(error => {
            data.value = []
            toast.error(getApiErrorMessage(error))
        })
    requests.push(listRequest)

    const countRequest = api.post(`/data-requests/count`, dr_filter)
        ?.then(response => {
            totalRecords.value = response.data || 0
        })
        ?.catch(error => {
            totalRecords.value = 0
            toast.error(getApiErrorMessage(error))
        })
    requests.push(countRequest)

    await Promise.all(requests)
    loading.value = false
}

const configParams = (event: any) => {
    rows.value = event?.rows
    return {
        "page": event?.page,
        "page_size": event?.rows,
        "include_total": false
    }
}

const get_customers = async () => {
  loading.value = true
  const cust_response = await api.get(`customers/light`)
  state.customers = cust_response.data
  loading.value = false
}

const SendToWindow = (url: string) => {
  window.open(url, '_blank')
}

onMounted(async () => {
  await get_customers();
  await loadData()
  hidePFGetHelpButton()
});

</script>

<style scoped></style>
@/helpers/interface/trueload
