import { ref, computed } from "vue"
import { defineStore } from "pinia";
import { useAPI } from "@/helpers/services/api"
import type { MailTemplates } from "@/helpers/interface/admin-page";
import type { HistoryAPIResponse, CandidateDocumentList, Owners, FlagsAPIResponse, SitusInfo } from "@/helpers/interface/candidates"

export const useCandidateDetailsStore = defineStore("candidateDetails", () => {

    const api = useAPI()

    const situsInfo = ref({
        tru_id: "",
        situs_full_taxroll: "",
        parcel_num: "",
        owner_name: "",
        legal_description: "",
        exmpt_description: "",
        market_value: "",
        assessed_value: "",
        deed_date: "",
        county_lookup_url: "",
        mail_full_taxroll: "",
        mail_county: "",
        queue: "",
        taxable_value: "",
        recovered_amount: null,
        assigned_user_id: "",
        status: null
    } as SitusInfo)

    const flags = ref([])
    const notes = ref([])
    const expiredFlags = ref([])
    const expiredNotes = ref([])
    const history = ref([] as HistoryAPIResponse[])
    const omnisearch = ref({})
    const mails = ref([])
    const documentList = ref<CandidateDocumentList[]>([])
    const mailTemplates = ref<MailTemplates[]>([])
    const owners = ref<Owners[]>([])
    const reasonsList = ref<string[]>([])


    const noteSorter = (a: FlagsAPIResponse, b: FlagsAPIResponse) => {
        const onDemandName = "On-Demand Investigation Request"
        if (a.name === onDemandName && b.name !== onDemandName) {
          return -1;
        }
        if (a.name !== onDemandName && b.name === onDemandName) {
          return 1;
        }
        // sort event_date in DESCENDING order
        return b.event_date.localeCompare(a.event_date);
    }


    const fetchSitusInfo = async (tru_id: string, errorHandler: Function) => {
        return api.get(`/taxroll/info?tru_id=${tru_id}`)
            .then(response => {
                const details = response.data 
                if (details && typeof details.legal_description === "string") {
                    details.legal_description = details.legal_description.replaceAll(/\n\r/g, " ").trim()
                }
                situsInfo.value = details
            })
            .catch(error => errorHandler(error))

    }
    const fetchFlags = async (tru_id: string, errorHandler: Function) => {
        return api.get(`/taxroll/${tru_id}/flags`)
            .then(response => {
                flags.value = response.data
            })
            .catch(error => errorHandler(error))
    }
    const fetchNotes = async (tru_id: string, errorHandler: Function) => {
        return api.get(`/taxroll/notes?tru_id=${tru_id}`)
            .then(response => {
                const data = response.data
                if (data) {
                    data.sort(noteSorter);
                }
                notes.value = data
            })
            .catch(error => errorHandler(error))
    }

    const fetchExpiredFlags = async (tru_id: string, errorHandler: Function) => {
        return api.get(`/taxroll/${tru_id}/flags?expired=true`)
            .then(response => {
                expiredFlags.value = response.data
            })
            .catch(error => errorHandler(error))
    }
    const fetchExpiredNotes = async (tru_id: string, errorHandler: Function) => {
        return api.get(`/taxroll/notes?tru_id=${tru_id}&expired=true`)
            .then(response => {
                expiredNotes.value = response.data
            })
            .catch(error => errorHandler(error))
    }

    const fetchHistory = async (tru_id: string, errorHandler: Function) => {
        return api.get(`/taxroll/history?tru_id=${tru_id}`)
            .then(response => {
                if (response.data) {
                    history.value = response.data
                }
            })
            .catch(error => errorHandler(error))
    }

    const fetchOmnisearchInfo = async (ul_id: string, errorHandler: Function) => {
        await api.get(`/taxroll/omni/${ul_id}`)
            .then(response => {
                if (response.data) {
                    omnisearch.value = response.data
                }
            })
            .catch(error => {
                omnisearch.value = {}
                return errorHandler(error)
            })
    }

    const fetchMailList = async (tru_id: string, errorHandler: Function) => {
        return await api.get(`/taxroll/mails/${tru_id}`)
            .then(response => {
                if (response.data.data) {
                    mails.value = response.data.data
                }
            })
            .catch(error => errorHandler(error))
    }

    const fetchDocumentList = async (tru_id: string, errorHandler: Function) => {
        await api.get(`/taxroll/documents/${tru_id}`)
            .then((response) => {
                documentList.value = response.data.data
            })
            .catch(error => errorHandler(error))
    }

    const deleteDocument = async (document_id: string) => {
        return await api.patch(`/taxroll/delete_document`, { document_id })
    }

    const fetchMailTemplateList = async (errorHandler: Function) => {
        await api.post(`/mailing/templates-list/`)
            .then((response) => {
                mailTemplates.value = response.data.data
            })
            .catch(error => errorHandler(error))
    }

    const fetchOwners = async (tru_id: string, errorHandler: Function) => {
        return api.get(`/taxroll/owners?tru_id=${tru_id}`)
            .then((response) => {
                owners.value = response.data
            })
            .catch(error => errorHandler(error))
    }

    const fetchReasonsList = async (errorHandler: Function, queueSelected: string) => {
        return await api.get(`/taxroll/reasons?category=${encodeURIComponent(queueSelected)}`)
            .then((response) => {
                reasonsList.value = response.data.data?.reasons || []
            })
            .catch(error => errorHandler(error))
    }

    const getSitusInfo = computed(() => situsInfo.value)
    const getFlags = computed(() => flags.value)
    const getNotes = computed(() => notes.value)
    const getExpiredFlags = computed(() => expiredFlags.value)
    const getExpiredNotes = computed(() => expiredNotes.value)
    const getHistory = computed(() => history.value)
    const getOmnisearch = computed(() => omnisearch.value)
    const getMailList = computed(() => mails.value)
    const getDocumentList = computed(() => documentList.value)
    const getMailTemplateList = computed(() => mailTemplates.value)
    const getOwners = computed(() => owners.value)
    const getReasonsList = computed(() => reasonsList.value)

    return {
        situsInfo,
        flags,
        expiredFlags,
        notes,
        expiredNotes,
        history,
        mails,
        documentList,
        mailTemplates,
        omnisearch,
        owners,
        getSitusInfo,
        getFlags,
        getExpiredFlags,
        getNotes,
        getExpiredNotes,
        getHistory,
        getOmnisearch,
        getMailList,
        getDocumentList,
        getMailTemplateList,
        getOwners,
        getReasonsList,
        fetchFlags,
        fetchExpiredFlags,
        fetchHistory,
        fetchNotes,
        fetchExpiredNotes,
        fetchSitusInfo,
        fetchOmnisearchInfo,
        fetchMailList,
        fetchDocumentList,
        fetchMailTemplateList,
        deleteDocument,
        fetchOwners,
        fetchReasonsList
    }
});
