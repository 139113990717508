<template>
    <div id="file-uploader">
        <label :for="id">
            <slot name="label">Upload File</slot>
        </label>
        <input type="file" :id="id" :accept="accept" :required="required" :disabled="disabled"
            @invalid.capture.prevent="elementRequiredHandler" @change="onFileChange" :multiple="multiple ? true : undefined"/>
    </div>
</template>

<script setup lang="ts">
const { required, id, elementRequiredHandler, accept, disabled, multiple } = defineProps<{
    required: boolean;
    id: string;
    elementRequiredHandler: (event: Event) => void;
    accept?: string;
    disabled?: boolean;
    multiple?: boolean;
}>()

const emit = defineEmits(["change", "invalid"])

const onFileChange = (event: Event) => emit("change", event)
</script>