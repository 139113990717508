<template>
  <div ref="ConfigListDataView" id="ConfigListDataView">
    <div class="row action-toolbar-container" id="page-header-action-section">
      <div class="col-6" v-if="!loading">
        <Button type="button" class="me-2" @click="SendToWindow($router.resolve({name: 'onboardingConfig', params: { id: 'new' }}).href)" severity="info">
          <span class="fa fa-edit" aria-hidden="true"></span> New
        </Button>
        <Button type="button" class="me-2" @click="toggleNotification(false)">
          <span class="fa fa-plus-circle" aria-hidden="true"></span> Assign Config
        </Button>
        <Button type="button" class="me-2" @click="toggleNotification(true)" severity="danger">
          <span class="fa fa-minus-circle" aria-hidden="true"></span> Unassign Config
        </Button>
        <Button type="button" class="me-2" @click="hideConfig" severity="warning">
          <span class="fa fa-archive" aria-hidden="true"></span> Hide
        </Button>
      </div>
      <div class="col-4" v-else>
        <Skeleton width="400px" height="34px" class="ms-2 mb-2"></Skeleton>
      </div>
      <div class="col-2" v-if="!loading">
        <input type="text"  id="customerInput" class="form-control" placeholder="Config Search" v-model="searchField">
      </div>
      <div class="col-2" v-if="!loading">
        <Button v-if="searchField" type="button" class="me-2" @click="search = searchField" severity="secondary">
          <span class="fa fa-solid fa-magnifying-glass" aria-hidden="true"></span>
        </Button>
        <Button v-if="search" type="button" class="me-2" @click="search = ''; searchField = ''" severity="secondary">
          <span class="fa fa-solid fa-circle-xmark" aria-hidden="true"></span>
        </Button>
      </div>
      <div class="col-2" v-else>
        <Skeleton width="225px" height="34px" class="ms-2 mb-2"></Skeleton>
      </div>
      <div class="ms-auto" style="width: fit-content;" v-if="!loading">
        <Checkbox v-model="showHidden" :input-id="'showHidden'" binary/>
        <label for="showHidden">Show Hidden</label>
      </div>
      <div class="ms-auto" style="width: fit-content;" v-else>
        <Skeleton width="150px" height="16px" class="ms-2 mb-2"></Skeleton>
        <Skeleton width="150px" height="22px" class="ms-2 mb-2"></Skeleton>
      </div>
    </div>

    <div v-if="loading">
      <table class="table table-striped">
        <tbody>
          <tr>
            <td colspan="6" style="text-align: center">
              <div class="spinner-border" style="width: 100px; height: 100px;" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <BasicStrapCards :loading="loading" :parent-data-view="ConfigListDataView" :total-records="totalRecords" :rows="rows" :first-index="first" :data="data" @onpage="(event) => onPage(event)" :key="reloader">
      <template #list="slotProps">
        <div v-for="(item, index) in (slotProps.data as LightConfig[])" :key="index" class="row dataview-list-row-card" :id="`row_${index}`">
          <div class="col-1 align-content-center" id="selectionColumn">
            <div class="p-3">
              <Checkbox v-model="selectedItems" :inputId="item.id" name="category" :value="item.id" />
            </div>
          </div>
          <div class="col align-content-center clipboard-copy-wrapper">
            <div class="p-3">
              <div class="mb-2">
                <a :href="'/onboarding/configs/' + item.id" target="_blank">
                  {{ item.name || 'Unnamed Config'}}
                </a>
              </div>
            </div>
          </div>
          <div class="col align-content-center">
            <div class="mb-2" v-for="(customer, index) in getAssignedVersions(item.id)" :key="index">
              {{ customer }}
            </div>
          </div>
          <div class="col align-content-center">
            <div class="mb-2">
              {{ getConfigType(item.id) }}
            </div>
          </div>
          <div class="col align-content-center">
            <div class="mb-2">
              Updated At: {{ new Date( item.published_at || 'Invalid Publishing Date' ).toLocaleDateString() }}
            </div>
          </div>
          <div class="col align-content-center">
            <div class="mb-2">
              Comment: {{ getVersion( item.version_id ) }}
            </div>
          </div>
        </div>
      </template>
    </BasicStrapCards>
  </div>
  <teleport to="body">
		<div class="modal-overlay" v-if="notificationVisibility.showNotification" @click="toggleNotification(removal)"></div>
		<OnboardingConfigPopup @closePopup="toggleNotification(removal)" class="notification" :config_id="selectedItems.pop()" :removal="removal"
			v-if="notificationVisibility.showNotification" />
	</teleport>
</template>

<script setup lang="ts">
import { useAPI } from "@/helpers/services/api"
import {reactive, onMounted, ref, computed, watch, onBeforeUnmount} from "vue";
import OnboardingConfigPopup from "@/components/Admin/Onboarding/Onboarding-configPopup.vue";
import type {
  LightConfigVersion, LightConfig, CustomerConfigList
} from "@/helpers/interface/onboarding";
import {getApiErrorMessage, hidePFGetHelpButton, scrollToView} from "@/helpers/common";
import Checkbox from "primevue/checkbox";
import BasicStrapCards from "@/components/Shared/BasicStrapCards.vue";
import Button from "primevue/button";
import Skeleton from "primevue/skeleton";
import type { LightCustomer } from "@/helpers/interface/trueload";
import type {DataTableEvent} from "@/helpers/interface/general";
import {toast} from "@/helpers/toast";

const state = reactive({
  customer_configs: [] as Array<CustomerConfigList>,
  config_versions: [] as Array<LightConfigVersion>
})

const ConfigListDataView = ref<HTMLElement | null>(null);
const loading = ref(false);
const first = ref(0);
const rows = ref(25);
const data = ref<LightConfig[]>([]);
const totalRecords = ref(0);
const currentPage = ref(1);
const dataParams = ref<DataTableEvent | null>(null);
const reloader = ref(0)
const searchField = ref<string>("")
const search = ref<string>("")
const removal = ref<boolean>(false)

const selectedItems = ref<string[]>([])

const showHidden = ref<boolean>(false)

const emits = defineEmits(["onpage"])

const notificationVisibility = ref({ showNotification: false })

watch([showHidden, search], () => {
  first.value = 0;
  onPage({page: 0, first: 0, rows: rows.value});
  reloader.value += 1;
})

const api = useAPI()

const toggleBodyScrolling = (allowScrolling: boolean) => document.body.style.overflow = allowScrolling ? 'auto' : 'hidden';
const toggleNotification = (is_removal: boolean) => {
  removal.value = is_removal;
	notificationVisibility.value.showNotification = !notificationVisibility.value.showNotification;
  if (!notificationVisibility.value.showNotification) {
    loadData();
  }
	toggleBodyScrolling(!notificationVisibility.value.showNotification);
}
onBeforeUnmount(() => toggleBodyScrolling(true))

const getVersion = (version_id: any) => {
  if (version_id){
    return state.config_versions.find(item => item.id === version_id)?.meta.created_by.comment || "Comment Not Found"
  }
  else {
    return "No Valid Version ID"
  }
}

const getAssignedVersions = (config_id: string) => {
  const assignedCustomers =  state.customer_configs
      .filter(item => item.taxroll_config === config_id
          || item.digital_config === config_id
          || item.ocr_config === config_id)
      .map(item => item.customer_name)
  if (assignedCustomers.length === 0) {
    return ["No Customers Assigned"]
  }
  else {
    return assignedCustomers
  }
}

const getConfigType = (config_id: string) => {
  if (state.customer_configs.find(item => item.taxroll_config === config_id)) {
    return "Taxroll"
  }
  else if (state.customer_configs.find(item => item.digital_config === config_id)) {
    return "Digital Applications"
  }
  else if (state.customer_configs.find(item => item.ocr_config === config_id)) {
    return "OCR Applications"
  }
  else {
    return "No Type Assigned"
  }
}

const hideConfig = async () => {
  loading.value = true
  for (const id of selectedItems.value) {
    try {
      await api.patch(`onboarding/configs/hide/${id}`)
    }
    catch (error: any) {
      toast.error(getApiErrorMessage(error))
    }
  }
  selectedItems.value = []
  await loadData({page: currentPage, rows: rows})
  loading.value = false
}

const configParams = (event: any) => {
    rows.value = event?.rows
    return {
        "page": event?.page,
        "page_size": event?.rows,
        "include_total": false
    }
}

const getCustomerConfigs = async () => {
  loading.value = true;
  const response = await api.get(`onboarding/customers/configs`);
  state.customer_configs = response.data;
  loading.value = false;
}

const getConfigVersions = async () => {
  loading.value = true;
  const response = await api.get(`onboarding/configs/versions/list`);
  state.config_versions = response.data;
  loading.value = false;
}

const SendToWindow = (url: string) => {
  window.open(url, '_blank')
}

const loadData = async (event: any = null) => {
    loading.value = true
    dataParams.value = { ...dataParams.value, page: event?.page || 1, rows: event?.rows || 25 }
    let payload = configParams(dataParams.value)

    payload.page = currentPage.value

    const requests = []

    const config_filter = {
      "limit": parseInt(payload.page_size),
      "offset": (parseInt(payload.page_size) * (parseInt(payload.page) - 1)),
      "show_hidden": showHidden.value,
      "search": search.value,
    }
    const listRequest = api.post(`/onboarding/configs/list`, config_filter)
        ?.then(response => {
            data.value = response.data || []
        })
        ?.catch(error => {
            data.value = []
            toast.error(getApiErrorMessage(error))
        })
    requests.push(listRequest)

    const countRequest = api.post(`/onboarding/configs/count`, config_filter)
        ?.then(response => {
            totalRecords.value = response.data || 0
        })
        ?.catch(error => {
            totalRecords.value = 0
            toast.error(getApiErrorMessage(error))
        })
    requests.push(countRequest)

    await Promise.all(requests)
    loading.value = false
}

const onPage = async (event: DataTableEvent) => {
    event.page += 1
    dataParams.value = event
    currentPage.value = event.page
    await loadData(event)
    scrollToView("row_0")
}

onMounted(async () => {
  await getCustomerConfigs()
  await getConfigVersions()
  await loadData()
  hidePFGetHelpButton()
});

</script>

<style scoped></style>
