<template>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><router-link :to="{ name: 'onboardingToolbox' }">Onboarding Toolbox</router-link></li>
        <li class="breadcrumb-item active" aria-current="page">{{ newIngest ? "Create New" : `${state.dataIngest?.id ||
          "Untitled"} (${state.dataIngest?.status})` }}</li>
      </ol>
    </nav>

    <div>
      <h3>{{ newIngest ? 'New' : 'Selected' }} Data Ingest</h3>
      <div v-if="state.dataIngestLoading" class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>

      <div v-if="!state.customers || state.customersLoading" class="spinner-border" role="status"><span
          class="visually-hidden">Loading customers list...</span></div>
      <div v-else class="mb-3">
        <label for="customerInput" class="form-label">Customer</label>
        <input type="text" class="form-control" id="customerInput" placeholder="Select Customer" :disabled="!newIngest"
          list="customers" v-model="customerSelectedLabel">
        <datalist id="customers">
          <option v-for="customer in state.customers" :key="customer.customer_id"
            :value="`(${customer.customer_id}) ${customer.name} ${customer.fips_code} ${customer.schema_name}`" />
        </datalist>
      </div>

      <div class="mb-3"></div>

      <div v-if="(!state.configs || state.configsLoading) && state.formValues.customer_id" class="spinner-border"
        role="status"><span class="visually-hidden">Loading configs list...</span></div>
      <div v-else-if="!state.dataIngestLoading && state.formValues.customer_id && invalid_customer_configs" class="text-center">
        Error: No configs assigned to customer! Please use config editor to assign configs to this customer.
      </div>
      <div v-else-if="!state.dataIngestLoading && state.formValues.customer_id" class="mb-3">
        <label for="configIdInput" class="form-label">Ingestion Type</label>
        <select class="form-control" id="configIdInput" placeholder="Select ingestion type"
          :disabled="!newIngest" v-model="state.selected_type">
          <option
              v-for="option in config_options"
              :key="option.value"
              :value="option.value"
              :disabled="option.disabled">
              {{ option.label }}
          </option>
        </select>
      </div>

      <div v-if="(!dataRequests || state.dataRequestsLoading) && state.formValues.customer_id" class="spinner-border"
        role="status"><span class="visually-hidden">Loading data request list...</span></div>
      <div v-else-if="!state.dataRequestsLoading && state.formValues.customer_id" class="mb-3">
        <label for="dataRequestList" class="form-label">Data Request</label>
        <select class="form-control" id="dataRequestList" placeholder="Select Data Request to Use"
          v-model="state.dataRequest" :disabled="!state.selected_type">
          <option
              v-for="dataRequest in dataRequests"
              :key="dataRequest.id"
              :value="dataRequest.id">
              {{ dataRequest.title }} ({{ new Date(dataRequest.updated_at).toDateString() }})
          </option>
        </select>
      </div>

      <div v-if="(!state.configs || state.configsLoading) && state.formValues.customer_id" class="spinner-border"
        role="status"><span class="visually-hidden">Loading configs list...</span></div>
      <div v-else-if="!state.dataIngestLoading && state.formValues.customer_id" class="mb-3">
        <label for="configIdInput" class="form-label">Config ID</label>
        <input type="text" class="form-control" id="configIdInput" placeholder="Select or Enter Config ID"
          :disabled="!(state.selected_type === 'custom')" list="configs" v-model="configSelectedLabel">
      </div>

      <div v-if="state.configVersionsLoading" class="spinner-border" role="status"><span class="visually-hidden">Loading
          config versions...</span></div>
      <div
        v-else-if="state.formValues.config_id && state.formValues.config_id.match(/[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}/i)"
        class="mb-3">
        <label for="configVersionInput" class="form-label">Config version</label>
        <select class="form-control" id="configVersionInput" :disabled="!newIngest"
          v-model="configVersionSelectedLabel">
          <option v-for="(configVersion, index) in state.configVersions" :key="configVersion.id"
            :value="`Version ${state.configVersions.length - index} (${configVersion.created_at})`">{{ `Version
            ${state.configVersions.length - index} (${configVersion.created_at})` }}</option>
        </select>
      </div>

      <div v-if="!newIngest" class="row pt-2">
        <a href="javascript:;" @click="showIngestParams = !showIngestParams" style="color: #444;">
          <span v-if="!showIngestParams"><i class="fa fa-caret-right"></i> Ingest Params</span>
          <span v-else><i class="fa fa-caret-up"></i> Less Params</span>
        </a>
      </div>


      <div class="mb-3" v-if="showIngestParams || newIngest">
        <div v-if="newIngest">Ingest settings</div>
        <div class="grid-container">
          <div>
            <input type="checkbox" class="mx-1" id="ingestInput" :disabled="!newIngest"
              v-model="state.formValues.ingest">
            <label for="ingestInput" class="form-label">Ingest</label>
          </div>
          <div>
            <input type="checkbox" class="mx-1" id="refreshInput" :disabled="!newIngest"
              v-model="state.formValues.refresh">
            <label for="refreshInput" class="form-label">Refresh</label>
          </div>
          <div />
          <div>
            <input type="checkbox" class="mx-1" id="createTestInput" :disabled="!newIngest"
              v-model="state.formValues.create_tests">
            <label for="createTestInput" class="form-label">Create Tests</label>
          </div>
          <div>
            <input type="checkbox" class="mx-1" id="runTestInput" :disabled="!newIngest"
              v-model="state.formValues.run_tests">
            <label for="runTestInput" class="form-label">Run Tests</label>
          </div>
          <div />
          <div>
            <input type="checkbox" class="mx-1" id="cassInput" :disabled="!newIngest" v-model="state.formValues.cass">
            <label for="cassInput" class="form-label">CASS</label>
          </div>
          <div>
            <input type="checkbox" class="mx-1" id="updCustomerRecordInput" :disabled="!newIngest"
              v-model="state.formValues.update_customer_record">
            <label for="updCustomerRecordInput" class="form-label">Update Customer Record</label>
          </div>
          <div>
            <input type="checkbox" class="mx-1" id="generateObservationsInput" :disabled="!newIngest"
              v-model="state.formValues.generate_observations">
            <label for="generateObservationsInput" class="form-label">Generate Observations</label>
          </div>
        </div>
        <div>
          <label for="configParamsInput" class="form-label">Additional params</label>
          <textarea class="form-control" id="configParamsInput" rows=10 placeholder="JSON formatted object"
            :disabled="!newIngest" @blur="prettifyParamsInput()" v-model="state.jsonText"></textarea>
        </div>
      </div>

      <div class="mb-3" v-if="!newIngest" style="margin-top: 20px">
        <label for="logs" class="form-label">Logs</label>
        <div v-if="state.logsLoading" class="spinner-border" role="status" style="width: 15px; height: 15px">
          <span class="visually-hidden">Loading...</span>
        </div>
        <textarea class="form-control" id="logs" v-bind:value="state.logsText" style="height: 50vh"
          :readonly="true"></textarea>
      </div>

      <div v-if="newIngest" style="padding-top: 10px">
        <button :disabled="state.dataIngestLoading || !isValidJson || !state.formValues.customer_id || !state.formValues.config_id" type="button"
          class="btn btn-primary me-1" @click="submitIngest()">Create Ingest</button>
      </div>
      <div v-else-if="state.dataIngest?.status.match('^(pending|running)$')" style="padding-top: 10px">
        <button :disabled="state.cancelLoading" type="button" style="background-color: darkred; color: white"
          class="btn me-1" @click="cancelIngest()">Cancel Ingest</button>
        <div v-if="state.cancelLoading" class="spinner-border" role="status" style="width: 25px; height: 25px">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import { computed, onBeforeUnmount, onMounted, reactive, ref, watch } from "vue";
import type { Customer } from "@/helpers/interface/trueload";
import { useRouter } from "vue-router";
import { useAPI } from "@/helpers/services/api";
import { toast } from "@/helpers/toast";

import { hidePFGetHelpButton } from '@/helpers/common';
import type {
  Config,
  ConfigVersion,
  Ingest,
  Log,
  LogRequest,
  NewIngest,
  CustomerConfigs,
  LightConfig
} from "@/helpers/interface/onboarding";
import type { DataRequest } from '@/helpers/interface/trueload'
import {json} from "@codemirror/lang-json";

const router = useRouter()
const dataIngestId = computed(() => router.currentRoute.value.params.id as string)
const newIngest = computed(() => dataIngestId.value === 'new')
const showIngestParams = ref(false)
const dataRequests = ref<DataRequest[]>([])
const state = reactive({
  dataIngest: null as Ingest | null,
  customers: [] as Customer[],
  configs: [] as LightConfig[],
  configVersions: [] as ConfigVersion[],
  dataRequest: '',
  selected_type: '',
  customerConfigs: {
    taxroll_config: null,
    ocr_config: null,
    digital_config: null,
  } as CustomerConfigs,
  dataIngestLoading: false,
  customersLoading: false,
  configsLoading: false,
  dataRequestsLoading: false,
  request_types: ['TaxRoll', 'Paper Applications', 'Digital Applications', 'Other'],
  configVersionsLoading: false,
  logsLoading: false,
  cancelLoading: false,
  lastLogLoadTime: 0,
  statusTimer: undefined as number | undefined,
  jsonText: '',
  isValidJson: true,
  logsText: '',
  logStreamEnded: false,
  formValues: {
    customer_id: '',
    config_id: '',
    config_version_id: null as number | null,
    ingest: true,
    refresh: false,
    create_tests: false,
    run_tests: false,
    config_params: {} as { [key: string]: string },
    cass: true,
    generate_observations: true,
    update_customer_record: true
  } as NewIngest,
})

const config_options = computed(() => (
    [
      {label: "Taxroll Refresh", value: 'taxroll', disabled: state.customerConfigs.taxroll_config === null},
      {label: "OCR Applications", value: 'ocr', disabled: state.customerConfigs.ocr_config === null},
      {label: "Digital Applications", value: 'digital', disabled: state.customerConfigs.digital_config === null},
      {label: "Other Config", value: 'custom', disabled: false},
    ]
))

watch(() => state.selected_type, (newValue) => {
  if (newValue === 'taxroll') {
    state.formValues.config_id = state.customerConfigs.taxroll_config
    state.request_types = ['TaxRoll', 'Other']

  }
  else if (newValue === 'ocr') {
    state.formValues.config_id = state.customerConfigs.ocr_config
    state.request_types = ['Paper Applications', 'Other']
  }
  else if (newValue === 'digital') {
    state.formValues.config_id = state.customerConfigs.digital_config
    state.request_types = ['Digital Applications', 'Other']
  }
  else  {
    state.formValues.config_id = ''
    state.request_types = ['TaxRoll', 'Paper Applications', 'Digital Applications', 'Other']
  }
  getDataRequests(state.formValues.customer_id)
})

const invalid_customer_configs = computed(() => (state.customerConfigs.taxroll_config === null) && (state.customerConfigs.ocr_config === null) && (state.customerConfigs.digital_config === null))

const customerSelectedLabel = computed({
  get() {
    return state.formValues.customer_id ? `(${state.formValues.customer_id}) ${state.customers.find(c => c.customer_id == state.formValues.customer_id)?.name}` : ''
  },
  set(value) {
    const match = value.match(/^\(([^)]+)\)/)
    if (match) {
      state.formValues.customer_id = match[1]
    }
  }
})

let isValidJson = computed({
  get() {
    return state.isValidJson
  },
  set(value) {
    state.isValidJson = value
    if (!state.isValidJson) {
      toast.error('JSON is incorrect!')
    }
  }
})

watch(() => state.customers, () => {
  if (state.formValues.customer_id && state.customers.find(c => c.customer_id === state.formValues.customer_id)) {
    state.formValues.customer_id = state.formValues.customer_id.valueOf()
  }
})

watch(() => state.dataIngest?.status, () => {
  toast.info(`Status changed to "${state.dataIngest?.status}"`, { 'duration': 0 })
})

watch(() => state.formValues.customer_id, () => {
  state.selected_type = ''
  getCustomerConfigs(state.formValues.customer_id)
});

const configSelectedLabel = computed({
  get() {
    if (state.formValues.config_id) {
      const match = state.formValues.config_id.match(/[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}/i)
      if (match) {
        const config = state.configs.find(c => c.id === match[0])
        return `(${config?.customer_id}) ${config?.id}`
      } else {
        return state.formValues.config_id
      }
    } else {
      return ''
    }
  },
  set(value) {
    const match = value.match(/[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}/i)
    if (match) {
      state.formValues.config_id = match[0]
      getConfigVersions()
    } else {
      state.formValues.config_version_id = undefined as unknown as number
      state.formValues.config_id = value
    }
  }
})

const configVersionSelectedLabel = computed({
  get() {
    if (state.formValues.config_version_id) {
      const configVersion = state.configVersions.find(version => version.id === state.formValues.config_version_id)
      return `Version ${state.configVersions.length - state.configVersions.indexOf(configVersion!)} (${configVersion?.created_at})`
    } else {
      toast.info("No version available")
      return "No version available"
    }
  },
  set(value) {
    const match = value.match(/Version (\d*) .*/i)
    if (match) {
      state.formValues.config_version_id = state.configVersions[state.configVersions.length - +match[1]].id
    } else {
      toast.error('Unknown version')
    }
  }
})

watch(() => state.configs, () => {
  if (state.formValues.config_id) {
    state.formValues.config_id = state.formValues.config_id.valueOf()
  }
})

const api = useAPI()

function prettifyParamsInput() {
  try {
    state.formValues.config_params = state.jsonText ? JSON.parse(state.jsonText) : {}
    state.jsonText = Object.keys(state.formValues.config_params).length ? JSON.stringify(state.formValues.config_params, undefined, 4) : ''
    isValidJson.value = true
  } catch (e) {
    isValidJson.value = !(e instanceof SyntaxError && state.jsonText !== '');
  }
}

const getCustomers = async () => {
  state.customersLoading = true
  const response = await api.get(`customers/?include_prospects=true`)
  state.customers = response.data
  state.customersLoading = false
}

const getCustomerConfigs = async (customer_id:string) => {
  state.configsLoading = true
  const response = await api.get(`onboarding/ingestions/configs/${customer_id}`)
  state.customerConfigs = response.data
  state.formValues.config_id = ''
  state.configsLoading = false
}

const getDataRequests = async (customer_id:string) => {
  state.dataRequestsLoading = true
  state.dataRequest = ''
  const status_filter = ["pending", "cancelled", "deleted", "failed", "partially_filled"]
  const response = await api.post(`data-requests/list`, {'customer_id': customer_id, 'status_filter': status_filter, 'request_types': state.request_types})
  dataRequests.value = response.data
  state.dataRequestsLoading = false
}

const getConfigs = async () => {
  state.configsLoading = true
  const response = await api.get(`onboarding/configs/`)
  state.configs = response.data
  state.configsLoading = false
}

const getConfigVersions = async () => {
  state.configVersionsLoading = true
  const response = await api.get(`onboarding/configs/${state.formValues.config_id}/versions`)
  state.configVersions = response.data
  state.configVersionsLoading = false
}

const createIngestion = async (ingestion: NewIngest): Promise<Ingest> => {
  state.dataIngestLoading = true
  const resp = await api.post(`onboarding/ingestions/`, ingestion)
  state.dataIngestLoading = false
  return resp.data
}

const submitIngest = async () => {
  if (newIngest.value) {
    if (state.dataRequest) {
      Object.assign(state.formValues.config_params, {'data_request_id': state.dataRequest})
    }
    const created = await createIngestion(state.formValues as NewIngest)
    await router.push({
      name: 'ingestion',
      params: {
        id: created.id!,
      }
    })
    window.location.reload()
    toast.success('Created new ingestion')
  }
}

const cancelIngest = async () => {
  state.cancelLoading = true
  await api.post(`onboarding/ingestions/${dataIngestId.value}/cancel`)
  await updateIngestionStatus()
  state.cancelLoading = false
}

const loadDataIngest = async (id: string) => {
  if (!id || id === 'new') {
    return
  }
  state.dataIngestLoading = true
  const response = await api.get(`onboarding/ingestions/${id}`)
  const dataIngest: Ingest = state.dataIngest = response.data
  state.dataIngestLoading = false
  state.formValues = {
    customer_id: dataIngest.customer_id,
    config_id: dataIngest.config_id,
    config_version_id: dataIngest.meta ? dataIngest.meta["input"]["config_version_id"] : undefined,
    ingest: dataIngest.meta ? dataIngest.meta["input"]["ingest"] : undefined,
    refresh: dataIngest.meta ? dataIngest.meta["input"]["refresh"] : undefined,
    create_tests: dataIngest.meta ? dataIngest.meta["input"]["create_tests"] : undefined,
    run_tests: dataIngest.meta ? dataIngest.meta["input"]["run_tests"] : undefined,
    config_params: dataIngest.meta ? dataIngest.meta["input"]["config_params"] : {},
    cass: dataIngest.meta ? dataIngest.meta["input"]["cass"] : undefined,
    generate_observations: dataIngest.meta ? dataIngest.meta["input"]["generate_observations"] : undefined,
    update_customer_record: dataIngest.meta ? dataIngest.meta["input"]["update_customer_record"] : undefined
  }
  state.jsonText = JSON.stringify(state.formValues.config_params, undefined, 4)

  await getConfigs()
  await getCustomerConfigs(dataIngest.customer_id)
  if (state.formValues.config_version_id) {
    await getConfigVersions()
  }
}

const loadIngestLogs = async () => {
  const logRequest = {
    start_time: state.lastLogLoadTime
  } as LogRequest
  state.logsLoading = true
  const response = await api.get(`onboarding/ingestions/${dataIngestId.value}/logs`, { params: logRequest })
  const newLogs: Log[] = response.data
  state.logsLoading = false

  const textarea = document.getElementById('logs') as HTMLInputElement

  newLogs.forEach(
    (log: Log) => {
      if (log.message === "STREAM END") {
        state.logStreamEnded = true
        return
      }
      if (!state.logStreamEnded) {
        state.lastLogLoadTime = log.timestamp + 1
        state.logsText += `${(new Date(log.timestamp)).toUTCString()} -- ${log.message}\n`;
        textarea.scrollTop = textarea.scrollHeight;
      }
    }
  );
  if (!state.logStreamEnded) {
    setTimeout(loadIngestLogs, 5000)
  } else {
    await updateIngestionStatus()
  }
}

const updateIngestionStatus = async () => {
  const response = await api.get(`onboarding/ingestions/${dataIngestId.value}`)
  state.dataIngest = response.data
  if (state.dataIngest?.status !== "pending" && !state.logStreamEnded) {
    state.logsText = ''
    clearInterval(state.statusTimer)
    setTimeout(loadIngestLogs, 500)
  }
}

const cleanUp = async () => {
  state.logStreamEnded = true
  clearInterval(state.statusTimer)
  toast.clear()
}

onMounted(async () => {
  await getCustomers()
  await loadDataIngest(dataIngestId.value)
  if (dataIngestId.value === 'new') {
    await getConfigs()
  }
  else if (state.dataIngest?.log_stream_name) {
    setTimeout(loadIngestLogs, 500)
  }
  else {
    state.logsText = 'No available logs'
    state.statusTimer = window.setInterval(updateIngestionStatus, 10000)
  }
  hidePFGetHelpButton();
});

onBeforeUnmount(async () => {
  await cleanUp()
});

</script>

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: 200px 200px 200px;
  gap: 5px;
  padding: 10px;
}
</style>