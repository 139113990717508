<template>
    <div>
        <TheHeader />
        <div id="applicationDetails" class="ms-3">
            <div v-if="enableToolbar" class="m-2 mt-3">
                <div class="row" id="page-header-action-section">
                    <div class="col">
                        <Button type="button" class="me-2" @click="update.status.value = 'approved'">
                            <span class="fa fa-check" aria-hidden="true"></span> Approve
                        </Button>
                        <Button type="button" class="me-2" @click="update.status.value = 'denied'" severity="danger">
                            <span class="fa fa-times" aria-hidden="true"></span> Deny
                        </Button>
                        <Button type="button" class="me-2" @click="update.status.value = 'snooze'" severity="secondary">
                            <span class="fa fa-clock" aria-hidden="true"></span> Snooze
                        </Button>
                        <SplitButton label="Assign" rounded class="me-2" :model="assignActionItems"
                            id="assign-action-item" @click="() => selectedAction = 'Assign'">
                            <span class="fa fa-user-plus" aria-hidden="true"></span>
                            Assign
                        </SplitButton>
                        <SplitButton label="Labels" rounded class="me-2" :model="labelActionItems"
                            id="label-action-item" @click="() => selectedAction = 'Add Label'" severity="info">
                            <span class="fa fa-tags" aria-hidden="true"></span>
                            Labels
                        </SplitButton>
                        <Button type="button" class="me-2" id="note-action-item"
                            @click="() => note.showNoteDialog.value = true" severity="info">
                            <span class="fa fa-comment" aria-hidden="true"></span> Note
                        </Button>
                        <SplitButton v-if="hasReadCustomersPermission" label="Regenerate Report" rounded class="me-2" :model="regenerateActionItems"
                            id="regenerate-pdf-action-item" @click="() => selectedAction = 'Regenerate PDF'"
                            severity="info">
                            <span class="fa fa-file-pdf" aria-hidden="true"></span>
                            Regenerate Report
                        </SplitButton>
                        <!-- <Button type="button" class="me-2" id="outreach-action-item" @click="showAssignOutreachCodeModal"
                            severity="info">
                            <span class="fa fa-envelope" aria-hidden="true"></span> Outreach
                        </Button> -->
                    </div>
                </div>
            </div>
            <div class="mt-1">
                <div class="row p-component ms-2">
                    <div class="col-5">
                        <div id="summary-pane">
                            <div id="summary-header-title" class="p-2">
                                <span class="p-tabview-title">Summary</span>
                            </div>
                            <ApplicationVetterSummaryDetails :tru_id="tru_id" ref="summaryDetailsComponent"
                                @summary-loaded="onSummaryLoaded" />
                        </div>
                        <div id="party-parcel-pane" :style="'height:' + (leftPanelHeight) + 'px; overflow-y: auto;'"
                            ref="leftPanelRef">
                            <Tabs value="0">
                                <TabList class="sticky-tablist">
                                    <Tab value="0">Parties</Tab>
                                    <Tab value="1">Parcel</Tab>
                                </TabList>
                                <TabPanels>
                                    <TabPanel value="0">
                                        <ApplicationVetterParties :tru_id="tru_id" />
                                    </TabPanel>
                                    <TabPanel value="1">
                                        <ApplicationVetterParcel :tru_id="tru_id" />
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>

                        </div>
                    </div>
                    <div class="col-7" :style="'height:' + (rightPanelHeight) + 'px; overflow-y: auto;'"
                        ref="rightPanelRef">
                        <div id="investigation-pane">
                            <Tabs value="0">
                                <TabList>
                                    <Tab value="0">Flags</Tab>
                                    <Tab value="1">Documents</Tab>
                                    <Tab value="2">History</Tab>
                                </TabList>
                                <TabPanels>
                                    <TabPanel value="0">
                                        <ApplicationVetterFlagsNotes :tru_id="tru_id" />
                                    </TabPanel>
                                    <TabPanel value="1">
                                        <ApplicationVetterDocumentList :tru_id="tru_id" />
                                    </TabPanel>
                                    <TabPanel value="2">
                                        <ApplicationVetterHistoryList :tru_id="tru_id" ref="historyListComponent" />
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="application-vetter-modals">
        <ModalDialog v-if="showUpdateStatusDialog" :title="`${ucfirst(update.statusText.value)} this application?`"
            :close="closeUpdateStatusDialog">
            <template v-if="update.status.value === 'snooze'">
                <div class="element-container">
                    <label for="snooze-until-datepicker" class="form-label form-label-required">Snooze Until</label>
                    <DatePicker id="snooze-until-datepicker" class="d-flex" required
                        v-model="update.snoozeUntilDate.value" />
                </div>
            </template>
            <Textarea v-model="update.optionalNote.value" :maxlength="MAX_NOTE_LENGTH" placeholder="Add note" />
            <template #footer>
                <Button @click="submitUpdateStatus(update.status.value)" id="update-status"
                    :disabled="update.loading.value">
                    <span class="fa fa-edit" aria-hidden="true" v-if="!update.loading.value"></span>
                    <span class="fa fa-spinner fa-spin" aria-hidden="true" v-if="update.loading.value"></span>
                    Submit
                </Button>
                <Button @click="closeUpdateStatusDialog" severity="secondary">
                    <span class="fa fa-times-circle" aria-hidden="true"></span> Close
                </Button>
            </template>
        </ModalDialog>

        <ModalDialog v-if="assign?.showAssignDialog.value" :title="`Assign this application to`"
            :close="closeAssignDialog">
            <Select :scrollHeight="PV_SCROLL_HEIGHT" v-model="assign.user.value" :options="userList" optionLabel="name"
                placeholder="-- Assign User --" class="d-flex" />
            <br>
            <Textarea v-model="assign.optionalNote.value" :maxlength="MAX_NOTE_LENGTH" placeholder="Add note" />
            <template #footer>
                <Button @click="submitAssign('assign')" id="assign" :disabled="assign.loading.value">
                    <span class="fa fa-plus-circle" aria-hidden="true" v-if="!assign.loading.value"></span>
                    <span class="fa fa-spinner fa-spin" aria-hidden="true" v-if="assign.loading.value"></span>
                    Assign
                </Button>
                <Button @click="closeAssignDialog" severity="secondary">
                    <span class="fa fa-times-circle" aria-hidden="true"></span> Close
                </Button>
            </template>
        </ModalDialog>

        <ModalDialog v-if="assign?.showUnassignDialog.value" :title="`Unassign this application`"
            :close="closeAssignDialog">
            <Textarea v-model="assign.optionalNote.value" :maxlength="MAX_NOTE_LENGTH" placeholder="Add note" />
            <template #footer>
                <Button @click="submitAssign('unassign')" id="unassign" :disabled="assign.loading.value"
                    severity="warning">
                    <span class="fa fa-user-times" aria-hidden="true" v-if="!assign.loading.value"></span>
                    <span class="fa fa-spinner fa-spin" aria-hidden="true" v-if="assign.loading.value"></span>
                    Unassign
                </Button>
                <Button @click="closeAssignDialog" severity="secondary">
                    <span class="fa fa-times-circle" aria-hidden="true"></span> Close
                </Button>
            </template>
        </ModalDialog>

        <ModalDialog v-if="assign?.showAssignToMeDialog.value" :title="`Assign this application to me`"
            :close="closeAssignDialog">
            <Textarea v-model="assign.optionalNote.value" :maxlength="MAX_NOTE_LENGTH" placeholder="Add note" />
            <template #footer>
                <Button @click="submitAssign('assigntome')" id="assign-to-me" :disabled="assign.loading.value">
                    <span class="fa fa-plus-circle" aria-hidden="true" v-if="!assign.loading.value"></span>
                    <span class="fa fa-spinner fa-spin" aria-hidden="true" v-if="assign.loading.value"></span>
                    Assign To Me
                </Button>
                <Button @click="closeAssignDialog" severity="secondary">
                    <span class="fa fa-times-circle" aria-hidden="true"></span> Close
                </Button>
            </template>
        </ModalDialog>

        <ModalDialog v-if="label?.showAddDialog.value" :title="`Add labels to this application`"
            :close="closeLabelDialog">
            <MultiSelect :show-toggle-all="false" placeholder="-- Select Label --" display="chip" required
                v-model="label.add.value" multiple optionLabel="label_name" :options="labelList" class="d-flex" />
            <br>
            <Textarea v-model="label.optionalNote.value" :maxlength="MAX_NOTE_LENGTH" placeholder="Add note" />
            <template #footer>
                <Button @click="submitLabel('add')" id="add-label" :disabled="label.loading.value">
                    <span class="fa fa-plus-circle" aria-hidden="true" v-if="!label.loading.value"></span>
                    <span class="fa fa-spinner fa-spin" aria-hidden="true" v-if="label.loading.value"></span>
                    Add
                </Button>
                <Button @click="closeLabelDialog" severity="secondary">
                    <span class="fa fa-times-circle" aria-hidden="true"></span> Close
                </Button>
            </template>
        </ModalDialog>

        <ModalDialog v-if="label?.showRemoveDialog.value" :title="`Remove labels from this application`"
            :close="closeLabelDialog">
            <MultiSelect :show-toggle-all="false" placeholder="-- Select Label --" display="chip" required
                v-model="label.remove.value" multiple optionLabel="label_name" :options="labelList" class="d-flex" />
            <br>
            <Textarea v-model="label.optionalNote.value" :maxlength="MAX_NOTE_LENGTH" placeholder="Add note" />
            <template #footer>
                <Button @click="submitLabel('remove')" id="remove-label" severity="warning"
                    :disabled="label.loading.value">
                    <span class="fa fa-trash" aria-hidden="true" v-if="!label.loading.value"></span>
                    <span class="fa fa-spinner fa-spin" aria-hidden="true" v-if="label.loading.value"></span>
                    Remove
                </Button>
                <Button @click="closeLabelDialog" severity="secondary">
                    <span class="fa fa-times-circle" aria-hidden="true"></span> Close
                </Button>
            </template>
        </ModalDialog>

        <ModalDialog v-if="note?.showNoteDialog.value" :title="`Add note to this application`" :close="closeNoteDialog">
            <Textarea v-model="note.note.value" :maxlength="MAX_NOTE_LENGTH" placeholder="Add note" />
            <template #footer>
                <Button @click="submitNote" id="note" :disabled="note.loading.value">
                    <span class="fa fa-edit" aria-hidden="true" v-if="!note.loading.value"></span>
                    <span class="fa fa-spinner fa-spin" aria-hidden="true" v-if="note.loading.value"></span>
                    Submit
                </Button>
                <Button @click="closeNoteDialog" severity="secondary">
                    <span class="fa fa-times-circle" aria-hidden="true"></span> Close
                </Button>
            </template>
        </ModalDialog>

        <ModalDialog v-if="newCode.showModal.value" :title="(currentOutreach ? 'Update' : 'Add') + ' Outreach'"
            :close="() => closeAssignOutreachCodeModal()">
            <div class="element-container">
                <label for="id" class="form-label form-label-required">Name</label>
                <Select :disabled="fetchOutreachCodesListLoading" :loading="fetchOutreachCodesListLoading"
                    :scrollHeight="PV_SCROLL_HEIGHT" inputId="id" v-model="newCode.id.value"
                    :options="outreachCodesList" optionLabel="code" optionValue="id" placeholder="-- Select --"
                    class="d-flex" />
            </div>
            <div class="element-container">
                <Textarea v-model="newCode.note.value" :maxlength="MAX_NOTE_LENGTH" placeholder="Add note" />
            </div>
            <template #footer>
                <Button @click="assignNewOutreachCode" :disabled="newCode.loading.value"
                    :severity="currentOutreach ? 'success' : 'info'">
                    <span class="fa" :class="{ 'fa-plus-circle': !currentOutreach, 'fa-edit': currentOutreach }"
                        aria-hidden="true" v-if="!newCode.loading.value"></span>
                    <span class="fa fa-spinner fa-spin" aria-hidden="true" v-if="newCode.loading.value"></span>
                    {{ currentOutreach ? "Update" : "Add" }}
                </Button>
                <Button @click="closeAssignOutreachCodeModal" severity="secondary">
                    <span class="fa fa-times-circle" aria-hidden="true"></span> Close
                </Button>
            </template>
        </ModalDialog>

        <ModalDialog v-if="regenerateReport?.showConfirmationDialog.value"
            :title="`Confirm PDF Regeneration`" :close="closeRegeneratePDFDialog">
            <Message severity="warn" :closable="false" class="my-2">
                <span v-if="selectedAction === regenerateAction[0]">
                    Are you sure you want to regenerate the PDF for this application?
                </span>
                <span v-else>
                    Are you sure you want to regenerate missing PDFs? <b>This process may take some time.</b>
                </span>
            </Message>

            <template #footer>
                <Button @click="regeneratePDF" id="note" :disabled="regenerateReport.loading.value">
                    <span class="fa fa-download" aria-hidden="true" v-if="!regenerateReport.loading.value"></span>
                    <span class="fa fa-spinner fa-spin" aria-hidden="true" v-if="regenerateReport.loading.value"></span>
                    Submit
                </Button>
                <Button @click="closeRegeneratePDFDialog" severity="secondary">
                    <span class="fa fa-times-circle" aria-hidden="true"></span> Close
                </Button>
            </template>
        </ModalDialog>
    </div>

    <AppMonitor />
</template>

<script setup lang="ts">
import { useRoute } from "vue-router"
import { toast } from "@/helpers/toast"
import { useAPI } from "@/helpers/services/api"
import { onMounted, ref, computed, onUnmounted, watch } from "vue"
import ModalDialog from "@/components/Shared/ModalDialog.vue"
import {
    APPVET_DETAILS_VIEW_ONLY_PATH,
    MAX_NOTE_LENGTH,
    calculateScrollHeight,
    getApiErrorMessage,
    ucfirst,
    PV_SCROLL_HEIGHT,
    InternalSettings,
    validateUserPermission
} from "@/helpers/common"
import { logPageView } from "@/helpers/insights"
import type { Label } from "@/helpers/interface/candidates"
import AppMonitor from "@/components/AppMonitor.vue"
import { useAdminDetails } from "@/stores/adminDetails";
import { useProfile } from "@/stores/profile"
import Button from 'primevue/button'
import SplitButton from 'primevue/splitbutton'
import Textarea from 'primevue/textarea'
import DatePicker from "primevue/datepicker"
import type { Auth0User, OutreachCodes } from "@/helpers/interface/admin-page"
import Select from 'primevue/select'
import MultiSelect from 'primevue/multiselect'
import TheHeader from "@/components/TheHeader.vue";
import Tabs from 'primevue/tabs'
import TabList from 'primevue/tablist';
import Tab from 'primevue/tab';
import TabPanels from 'primevue/tabpanels';
import TabPanel from 'primevue/tabpanel';
import ApplicationVetterSummaryDetails from "@/components/ApplicationVetter/ApplicationVetterSummaryDetails.vue"
import ApplicationVetterParties from "@/components/ApplicationVetter/ApplicationVetterParties.vue"
import ApplicationVetterParcel from "@/components/ApplicationVetter/ApplicationVetterParcel.vue"
import ApplicationVetterFlagsNotes from "@/components/ApplicationVetter/ApplicationVetterFlagsNotes.vue"
import ApplicationVetterHistoryList from "@/components/ApplicationVetter/ApplicationVetterHistoryList.vue"
import ApplicationVetterDocumentList from "@/components/ApplicationVetter/ApplicationVetterDocumentList.vue"
import { useAuth0 } from '@auth0/auth0-vue';
import {
    performLabelUpdate,
    performAssignAction,
    performUpdateStatusAction,
    performNoteAction
} from "@/helpers/bulkActions"
import { ClaimsFields } from "@/helpers/roles"
import { useTaxroll } from "@/stores/taxroll"
import dayjs from "dayjs"
import type { AxiosError } from "axios"
import Message from "primevue/message"

type StatusCode = "approved" | "denied" | "snooze"
const { user } = useAuth0();
const storeAdminDetails = useAdminDetails();
const storeTaxroll = useTaxroll();
const api = useAPI()
const taxroll = useTaxroll()
const route = useRoute()
const storeProfile = useProfile();
const tru_id = route?.params?.tru_id as string
const hasReadCustomersPermission = computed(() => validateUserPermission("read", "customers"))

/*** Update Status ***/
const update = {
    status: ref<StatusCode | "">(""),
    optionalNote: ref(""),
    snoozeUntil: ref<string>(""),
    snoozeUntilDate: ref<Date | null>(null),
    loading: ref(false),
    statusText: computed(() => {
        switch (update.status.value) {
            case "approved":
                return "Approve";
            case "denied":
                return "Deny";
            case "snooze":
                return "Snooze";
            default:
                return "";
        }
    }),
    parentURL: "/applications"
}
const selectedAction = ref("")
const showUpdateStatusDialog = computed(() => (update.status.value !== ""))
const submitUpdateStatus = async (status: string) => {
    const additionalParam = { tru_ids: [tru_id] }
    const result = await performUpdateStatusAction(api, status, update, additionalParam)
    if (result === "success") {
        refreshData()
        closeUpdateStatusDialog()
    }
    update.loading.value = false
}

const closeUpdateStatusDialog = () => {
    selectedAction.value = ""
    update.status.value = ""
    update.optionalNote.value = ""
}

/*** Assign ***/
const assignActionItems = [
    { label: 'Assign', command: () => selectedAction.value = "Assign" },
    { label: 'Unassign', command: () => selectedAction.value = "Unassign" },
    {
        label: 'Assign to me',
        command: () => selectedAction.value = "Assign To Me",
        disabled: !(user.value?.[ClaimsFields['app_metadata']]?.is_client)
    },
]
const assign = {
    sub: user.value?.sub,
    user: ref<Auth0User | null>(null),
    optionalNote: ref(""),
    loading: ref(false),
    showAssignDialog: computed(() => selectedAction.value === "Assign"),
    showAssignToMeDialog: computed(() => selectedAction.value === "Assign To Me"),
    showUnassignDialog: computed(() => selectedAction.value === "Unassign"),
    parentURL: "/applications"
}
const userList = computed(() => storeAdminDetails.getUsersList)
const fetchUsersList = async () => {
    await storeAdminDetails.fetchUsersList(loadFailureHandler)
}
const submitAssign = async (action: "assign" | "unassign" | "assigntome") => {
    const additionalParam = { tru_ids: [tru_id] }
    const result = await performAssignAction(api, action, assign, additionalParam)
    if (result === "success") {
        refreshData()
        closeAssignDialog()
    }
    assign.loading.value = false

}
const closeAssignDialog = () => {
    selectedAction.value = ""
    assign.user.value = null
    assign.optionalNote.value = ""
}

/*** Regenerate Report ***/
const regenerateAction = ["Regenerate PDF", "Regenerate Missing PDFs"]
const regenerateActionItems = [
    { label: regenerateAction[0], command: () => selectedAction.value = regenerateAction[0] },
    { label: regenerateAction[1], command: () => selectedAction.value = regenerateAction[1] },
]
const regenerateReport = {
    loading: ref(false),
    showConfirmationDialog: computed(() => regenerateAction.includes(selectedAction.value)),
    parentURL: "/applications/application_pdf/regenerate"
}
const regeneratePDF = async () => {
    regenerateReport.loading.value = true
    try {
        const requestPDF = selectedAction.value === regenerateAction[0]
        const url = requestPDF ? `${regenerateReport.parentURL}/${tru_id}` : `${regenerateReport.parentURL}/all`
        if (requestPDF) {
            await api.post(url)
            toast.success("Your submission was successful", { duration: 8000 })
        }
        else {
            api.post(url)
            toast.success("The request to regenerate missing PDFs has been submitted. You will receive an email once the process is complete.", { duration: 8000 })
        }
        closeRegeneratePDFDialog()
    }
    catch (error: any) {
        toast.error(getApiErrorMessage(error))
    }
    regenerateReport.loading.value = false
}
const closeRegeneratePDFDialog = () => {
    selectedAction.value = ""
}

/*** Label ***/
const labelActionItems = [
    { label: 'Add', command: () => selectedAction.value = "Add Label" },
    { label: 'Remove', command: () => selectedAction.value = "Remove Label" },
]
const label = {
    add: ref<Label[]>([]),
    remove: ref<Label[]>([]),
    optionalNote: ref(""),
    loading: ref(false),
    showAddDialog: computed(() => selectedAction.value === "Add Label"),
    showRemoveDialog: computed(() => selectedAction.value === "Remove Label"),
    parentURL: "/applications"
}
const labelList = ref<Label[]>([])
const submitLabel = async (action: "add" | "remove") => {
    const additionalParam = { tru_ids: [tru_id] }
    const result = await performLabelUpdate(api, action, label, additionalParam)
    if (result === "success") {
        refreshData()
        closeLabelDialog()
    }
    label.loading.value = false
}
const closeLabelDialog = () => {
    selectedAction.value = ""
    label.add.value = []
    label.remove.value = []
    label.optionalNote.value = ""
}

/*** Note ***/
const note = {
    note: ref(""),
    loading: ref(false),
    showNoteDialog: ref(false),
    parentURL: "/applications"
}
const submitNote = async () => {
    const additionalParam = { tru_ids: [tru_id] }
    const result = await performNoteAction(api, note, additionalParam)
    if (result === "success") {
        refreshData(false)
        closeNoteDialog()
    }
    note.loading.value = false
}
const closeNoteDialog = () => {
    selectedAction.value = ""
    note.note.value = ""
    note.showNoteDialog.value = false
}


const refreshData = (summaryDetails: boolean = true, historyList: boolean = true) => {
    if (summaryDetails) storeTaxroll.fetchAppSummaryDetail(tru_id, loadFailureHandler)
    if (historyList) storeTaxroll.fetchAppHistoryList(tru_id, loadFailureHandler)
}

const summaryDetailsComponent = ref()
const historyListComponent = ref()
const pageErrorMessage = ref("")

const fetchLabelsDropdownList = async () => {
    try {
        const response = await api.get('/labels/?applications=true');
        const items = response.data ? response.data : []
        labelList.value = items as Label[]
    }
    catch (error: any) {
        labelList.value = [];
        toast.error(getApiErrorMessage(error))
    }
}

const loadFailureHandler = (error: any) => {
    const message = getApiErrorMessage(error, { "featureName": "Report" })
    pageErrorMessage.value = message
    toast.error(message)
}

const rightPanelHeight = ref<number>(650)
const rightPanelRef = ref<HTMLElement | null>(null)
const leftPanelHeight = ref<number>(300)
const leftPanelRef = ref<HTMLElement | null>(null)
const setDynamicHeights = () => {
    const BUFFER = 45
    rightPanelHeight.value = calculateScrollHeight(rightPanelRef.value, BUFFER)
    leftPanelHeight.value = calculateScrollHeight(leftPanelRef.value, BUFFER, 270 /*minHeight*/)
}

const enableToolbar = computed(() => {
    return !(window.location.pathname.startsWith(APPVET_DETAILS_VIEW_ONLY_PATH))
})

const fetchDocumentList = async () => {
    await taxroll.fetchDocumentList(tru_id, loadFailureHandler)
}

const getDefaultSnoozeDays = async () => update.snoozeUntilDate.value = dayjs().add(await storeAdminDetails.getDefaultSnoozeDays(InternalSettings.application_default_snooze_days), "day").toDate()


// Outreach
const summaryDetails = computed(() => (storeTaxroll.getAppSummaryDetail))
const currentOutreach = computed(() => (summaryDetails.value?.outreach_id))
const fetchOutreachCodesListLoading = ref(true)
const outreachCodesList = ref<OutreachCodes[] | null>(null)
const showAssignOutreachCodeModal = () => {
    newCode.showModal.value = true
    newCode.id.value = currentOutreach.value || null
}
const fetchOutreachCodeList = async () => {
    fetchOutreachCodesListLoading.value = true
    try {
        const { data } = await api.get(`outreach`)
        outreachCodesList.value = data
    } catch (error: unknown) {
        const err = error as AxiosError
        loadFailureHandler(err)
    }
    finally {
        fetchOutreachCodesListLoading.value = false
    }
}
const closeAssignOutreachCodeModal = () => newCode.showModal.value = false
const newCode = {
    id: ref<number | null>(null),
    note: ref(""),
    showModal: ref(false),
    loading: ref(false)
}
const assignNewOutreachCode = async () => {
    if (!newCode.id.value) {
        toast.error("Please select an outreach")
        return
    }

    newCode.loading.value = true
    const payload = {
        tru_ids: [
            tru_id
        ],
        note: newCode.note.value,
        outreach_id: newCode.id.value
    }
    try {
        await api.post("applications/outreach", payload)
        toast.success("Your submission was successful")
        storeTaxroll.fetchAppSummaryDetail(tru_id, loadFailureHandler)
        storeTaxroll.fetchAppHistoryList(tru_id, loadFailureHandler)
        newCode.showModal.value = false
        newCode.note.value = ""
    } catch (error: unknown) {
        const err = error as AxiosError
        loadFailureHandler(err)
    }
    finally {
        newCode.loading.value = false
    }
}

onMounted(async () => {
    window.addEventListener("resize", setDynamicHeights)

    await storeProfile.fetchUserPermissions()
    await Promise.all([
        fetchLabelsDropdownList(),
        fetchUsersList(),
    ])
    fetchDocumentList()
    getDefaultSnoozeDays()
    fetchOutreachCodeList()
    newCode.id.value = currentOutreach.value || null
})

onUnmounted(async () => {
    window.removeEventListener("resize", setDynamicHeights)
})

const onSummaryLoaded = () => {
    setTimeout(setDynamicHeights, 10)
}

watch(() => update.snoozeUntilDate.value, (newDateValue) => {
    update.snoozeUntil.value = newDateValue?.toString() || ""
})

logPageView({ "pageName": "new-application-report", "itemId": tru_id })

</script>

<style>
@import "@/assets/appvet.css";
</style>